export const DETECT_INIT = "DETECT_INIT" as const;
export const DETECT_ERROR = "DETECT_ERROR" as const;
export const DETECT_SUCCESS = "DETECT_SUCCESS" as const;
export const DETECT_RESET = "DETECT_RESET" as const;

export function detectInit(payload: any) {
  return {
    type: DETECT_INIT,
    payload,
  };
}

export function detectError(error: any) {
  return {
    type: DETECT_ERROR,
    error,
  };
}

export function detectSuccess(payload: any) {
  return {
    type: DETECT_SUCCESS,
    payload,
  };
}

export function detectReset() {
  return {
    type: DETECT_RESET,
    // payload
  };
}

export type GetDetectActionsType =
  | ReturnType<typeof detectInit>
  | ReturnType<typeof detectError>
  | ReturnType<typeof detectSuccess>
  | ReturnType<typeof detectReset>;
