import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootReducerType } from "../src/modules";
import { logoutSuccess, loginSuccess } from "../src/modules/Login";
import Login from "./views/Login";
import Normal from "./views/Normal";
import Embed from "./views/Embed";
import Cookies from "universal-cookie";
import Preview from "./views/Preview";
import Register from "./views/Login/Register";

const cookies =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("@electron/remote").session.defaultSession.cookies
    : new Cookies();
const Router: React.FC = () => {
  React.useEffect(() => {
    cookies.set({
      url: "http://localhost:3000",
      name: "accessToken",
      value: "",
    });
  }, []);

  const dispatch = useDispatch();
  const isLogin = useSelector(
    (state: RootReducerType) => state.login.isLoggingIn
  );
  const accessToken = useSelector(
    (state: RootReducerType) => state.login.token
  );

  React.useEffect(() => {
    if (process.env.REACT_APP_TARGET === "electron") {
      if (accessToken?.accessToken) dispatch(loginSuccess(accessToken));
      else dispatch(logoutSuccess());
      // cookies
      //   .get({ name: "accessToken" })
      //   .then((cookies: any) => {
      //     const token = cookies[0].value;
      //     if (token === undefined || token === "" || token === null) {
      //       dispatch(logoutSuccess());
      //     } else {
      //       dispatch(loginSuccess(accessToken));
      //     }
      //   })
      //   .catch((error: any) => {
      //     console.log(error, "tokenerr");
      //   });
    }
  }, [isLogin]);

  return (
    <Routes>
      <Route path="/preview" element={<Preview />} />
      {!isLogin ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </>
      ) : (
        <>
          <Route path="/normal" element={<Normal />} />
          <Route path="/normal/screen/:id" element={<Embed />} />
          <Route path="*" element={<Navigate replace to="/normal" />} />
        </>
      )}
    </Routes>
  );
};

export default Router;
