import { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfView = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const testRef = useRef();
  const [test, setTest] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);

    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  return (
    <div ref={testRef}>
      <Document
        file={props.pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        style={{ width: "100%", height: "261.5px" }}
      >
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};

export default PdfView;
