import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducerType } from "../../modules";
import ScreenHeader from "./ScreenHeader";
import ScreenContainer from "./ScreenContainer";
import ErrorModal from "../../components/ErrorModal";
import DownloadModal from "../../components/DownloadModal";
// const log = require("electron-log");

import "../../assets/scss/Preview.scss";
import BottomBar from "./BottomBar";
import Loading from "../../components/Loading";

const remote =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("electron")
    : null;

type SubwindowInfo = {
  imgURL: string;
  title: string;
};

const Preview = () => {
  const EmbedState = useSelector((state: RootReducerType) => state.Embed);
  const [subinfo, setSubinfo] = useState<SubwindowInfo>({
    imgURL: "",
    title: "",
  });
  const [errorModal, setErrorModal] = useState({
    open: false,
    title: "",
    content: "",
  });
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [panning, setPanning] = useState(false);
  const [rotate, setRotate] = useState<number>(0);

  const handleDownloadModal = () => {
    setIsDownloadOpen(!isDownloadOpen);
  };
  const handleClickSaveFile = async (e: string) => {};

  //에러 팝업 확인 버튼 기능
  const handleErrorModal = (title: any) => {
    setErrorModal({
      ...errorModal,
      open: false,
    });
  };

  console.log(EmbedState.data?.products);

  return (
    <div className="preview">
      <div className="window">
        <div className="screen">
          <ScreenHeader
            title={EmbedState.embedData?.title}
            imgURL={EmbedState.data?.labcodeImageUrl}
            setStopDownload={false}
          />
          <div className="screen-containers">
            <ScreenContainer
              scale={scale}
              setScale={setScale}
              imgURL={EmbedState.data?.products.labcodeImage}
              panning={panning}
              setPanning={setPanning}
              rotate={rotate}
            />
          </div>
          <div className="screen-bottombar2">
            <BottomBar
              scale={scale}
              setScale={setScale}
              panning={panning}
              setPanning={setPanning}
              rotate={rotate}
              setRotate={setRotate}
            />
          </div>

          {/* <DownloadModal
        open={isDownloadOpen}
        handleSaveModal={handleDownloadModal}
        handleClickSaveFile={(e: any) => handleClickSaveFile(e)}
        numPages={1}
      /> */}
        </div>
      </div>
    </div>
  );
};
export default Preview;
