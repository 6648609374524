import failure from "../../assets/icons/normal/failure.svg";
import URL from "../../images/pages/url.svg";
import cert from "../../assets/icons/normal/cert.svg";

interface Props {
  detectdata: any;
  detectItem: {
    item: string | null;
    file: File | null;
    fileName: string | null;
  };
}

const DetectRightSideBar = (props: Props) => {
  const htmlToImage = require("html-to-image");

  const openNewWindow = (url: string) => {
    const windowFeature = "width=1440, height=1024";
    window.open(url, "_blank", windowFeature);
  };

  const handleFileDownload = async () => {
    htmlToImage
      .toPng(document.getElementById("ResultImage"))
      .then(function (dataUrl: any) {
        // console.log(dataUrl);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.setAttribute("download", `DetectResult.png`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const dateStringCutter = (value: string) => {
    let hours = Number(value.slice(11, 13));
    let ampm = hours >= 12 ? "오후" : "오전";
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(
      8,
      10
    )} ${ampm} ${hours}:${value.slice(14, 16)}`;
  };

  return (
    <>
      {props.detectdata === null || props.detectdata.message === "DATA_NONE" ? (
        <div className="container-result">
          <div className="container-result-img">
            <div className="container-result-img-title">검증결과</div>
            <div
              className="container-result-img-box"
              style={{ border: "5px solid #EF284C" }}
            >
              <div className="container-result-img-box-image">
                <img src={props.detectItem.item as string} />
              </div>
              <div className="container-result-img-box-description">
                <div className="container-result-img-box-description-first">
                  <div>
                    <img src={failure} />
                    기술 검증 실패
                  </div>
                  <div>위 이미지는 랩코드 기술이 확인되지 않았습니다.</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-result-download">
              <button>결과 다운로드</button>
            </div> */}
        </div>
      ) : (
        <div className="container-result">
          <div className="container-result-img">
            <div className="container-result-img-title">검증결과</div>
            <div
              className="container-result-img-box"
              id="ResultImage"
              style={{ border: "5px solid #00B48E" }}
            >
              <div className="container-result-img-box-image">
                <img src={props.detectdata.labCodeImageUrl} />
              </div>

              <div className="container-result-img-box-description">
                <div className="container-result-img-box-description-first">
                  <div>
                    <img src={cert} />
                    기술 검증 완료
                  </div>
                  <div>위 이미지는 랩코드 기술이 적용된 이미지 입니다.</div>
                </div>
                <div className="container-result-img-box-description-second">
                  <div className="container-result-img-box-description-second-title">
                    ID
                  </div>
                  <div className="container-result-img-box-description-second-content">
                    {props.detectdata.ownerEmail}
                  </div>
                  <div className="container-result-img-box-description-second-title">
                    최초 적용 날짜
                  </div>
                  <div className="container-result-img-box-description-second-content">
                    {dateStringCutter(props.detectdata.created)}
                  </div>
                </div>
                <div className="container-result-img-box-description-third">
                  <div>링크</div>
                  <div className="container-result-img-box-description-third-link">
                    {props.detectdata.url}
                  </div>
                  <button
                    onClick={() => openNewWindow(props.detectdata.url)}
                    style={{ width: "24px" }}
                  >
                    <img src={URL} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-result-download">
            <button onClick={handleFileDownload}>검증 결과 다운로드</button>
          </div>
        </div>
      )}
    </>
  );
};

export default DetectRightSideBar;
