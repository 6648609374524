import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerType } from "../../modules";
import plus from "../../assets/icons/normal/plus.svg";
import UploadSVG from "../../assets/icons/normal/UploadSVG";
import LoadingSVG from "../../assets/icons/normal/LoadingSVG";
import ResultSVG from "../../assets/icons/normal/ResultSVG";
import tooltip from "../../assets/icons/normal/tooltip.svg";
import alphaTooltip from "../../assets/icons/normal/alphaTooltip.svg";
import gt from "../../assets/icons/normal/gt.svg";
import { detectInit } from "../../modules/Detect";
import Loading from "../../components/Loading";
import Axios from "axios";
import DetectContainer from "./DetectContainer";
import DetectRightSideBar from "./DetectRightSideBar";

interface Props {
  detectItem: {
    item: string | null;
    file: File | null;
    fileName: string | null;
  };
  setDetectItem: Dispatch<
    SetStateAction<{
      item: string | null;
      file: File | null;
      fileName: string | null;
    }>
  >;
  isDetected: boolean;
  setIsDetected: any;
  log: any[];
  setLog: any;
  setErrorModal: any;
}

const DetectMode = (props: Props) => {
  const dispatch = useDispatch();
  const UpdateDetectFileRef = useRef<HTMLInputElement | null>(null);
  const DetectFileRef = useRef<HTMLInputElement | null>(null);
  const DetectState = useSelector((state: RootReducerType) => state.Detect);
  const { detectloading, detectdata, detecterror } = DetectState;
  const [isProgress, setIsProgress] = useState("upload");
  // const [isProgress, setIsProgress] = useState("loading");
  const [modal, setModal] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    imgCount: 0,
    curCount: 0,
  });

  const mode = process.env.REACT_APP_MODE === "app";

  useEffect(() => {
    // let date = new Date();
    // if (detectdata && props.detectItem.fileName !== null) {
    //   if (detectdata.message === "객체 상세 조회를 완료하였습니다.") {
    //     props.setLog([
    //       ...props.log,
    //       {
    //         isSuccess: true,
    //         content: `${getAMPM(date)} ${props.detectItem.fileName} Detected as Success`,
    //       },
    //     ]);
    //   } else {
    //     props.setLog([
    //       ...props.log,
    //       {
    //         isSuccess: false,
    //         content: `${getAMPM(date)} ${props.detectItem.fileName} Detected as Fail`,
    //       },
    //     ]);
    //   }
    // }
    if ((detectdata && props.detectItem.fileName !== null) || detecterror) {
      setIsProgress("result");
    }
    // }else i}f{
  }, [detectdata, detecterror]);

  useEffect(() => {
    if (detectloading) setIsProgress("loading");
  }, [detectloading]);

  const getAMPM = (date: Date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let ampm = hours > 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutesStr = minutes < 10 ? "0" + String(minutes) : String(minutes);
    let secondsStr = seconds < 10 ? "0" + String(seconds) : String(seconds);

    return `[${date.getFullYear()}-${date.getMonth() + 1}-${
      date.getDate() + 1
    } ${ampm} ${hours}:${minutesStr}:${secondsStr}]`;
  };

  const handleUpdateDetect = () => {
    props.setIsDetected(false);
    if (!UpdateDetectFileRef.current) return;
    UpdateDetectFileRef.current.click();
  };

  const handleClickDetectFile = () => {
    if (!DetectFileRef.current) return;
    DetectFileRef.current.click();
  };

  const handleDeleteDetect = () => {
    props.setIsDetected(false);
    props.setDetectItem({ item: null, file: null, fileName: null });
  };

  // useEffect(() => {
  //   DetectFileRef.current?.addEventListener("drop", dropHandler);
  //   DetectFileRef.current?.addEventListener("dragover", dragOverHandler);
  //   DetectFileRef.current?.addEventListener("change", changeHandler);

  //   return () => {
  //     DetectFileRef.current?.removeEventListener("drop", dropHandler);
  //     DetectFileRef.current?.removeEventListener("dragover", dragOverHandler);
  //     DetectFileRef.current?.removeEventListener("change", changeHandler);
  //   };
  // }, [DetectFileRef]);

  const CreateDetect = (event: any) => {
    if (!event.target.files[0]) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      props.setDetectItem({
        item: reader.result as string,
        file: file,
        fileName: file.name,
      });
    };
  };

  const UpdateDetect = (event: any) => {
    if (!event.target.files[0]) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      props.setDetectItem({
        item: reader.result as string,
        file: file,
        fileName: file.name,
      });
    };
  };

  const handleDetect = () => {
    props.setIsDetected(true);
    // setIsProgress("result");
    if (props.detectItem.item === null) {
      props.setErrorModal({
        open: true,
        title: "이미지를 찾을 수 없습니다.",
        content: [
          "이미지를 업로드한 뒤에 Detect 버튼을",
          <br key="detect-error" />,
          "눌러주세요.",
        ],
      });
      // setIsProgress("result");
    } else {
      console.log("Detect Button Click!");
      const formData = new FormData();
      console.log(props.detectItem.file?.name);
      formData.append("embeddedImage", props.detectItem.file as File);
      dispatch(detectInit(formData));
    }
  };

  const handleReDetect = () => {
    props.setDetectItem({
      item: null,
      file: null,
      fileName: null,
    });
    setIsProgress("upload");
    // detecterror = null
  };

  return (
    <div className="detectWrapper">
      <div
        className={
          isProgress === "result"
            ? "container-detect result"
            : "container-detect"
        }
        id="scroller"
      >
        <div className="progressBar">
          <div className="progressBar-progress">
            <div className="progressBar-progress-first">
              <UploadSVG isProgress={isProgress} />
              <div
                style={{
                  color: isProgress === "upload" ? "#F6F7F8" : "#B3B8C0",
                }}
              >
                이미지 업로드
              </div>
            </div>
            <div>
              <img src={gt} />
            </div>
            <div className="progressBar-progress-second">
              <LoadingSVG isProgress={isProgress} />
              <div
                style={{
                  color: isProgress === "loading" ? "#F6F7F8" : "#B3B8C0",
                }}
              >
                검증하기
              </div>
            </div>
            <div>
              <img src={gt} />
            </div>
            <div className="progressBar-progress-third">
              <ResultSVG isProgress={isProgress} />
              <div
                style={{
                  color: isProgress === "result" ? "#F6F7F8" : "#B3B8C0",
                }}
              >
                결과보기
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="progressBar-tooltip">
            <div>
              <img className="tooltipImg" src={tooltip} /> 기술 확인 모드란?
              <img className="alphaTooltip" src={alphaTooltip} />
            </div>
          </div>
        </div>
        <DetectContainer
          detectItem={props.detectItem}
          detectdata={detectdata}
          handleUpdateDetect={handleUpdateDetect}
          handleDeleteDetect={handleDeleteDetect}
          UpdateDetectFileRef={UpdateDetectFileRef}
          UpdateDetect={UpdateDetect}
          handleClickDetectFile={handleClickDetectFile}
          DetectFileRef={DetectFileRef}
          CreateDetect={CreateDetect}
          detectloading={detectloading}
          isProgress={isProgress}
          setDetectItem={props.setDetectItem}
        />
        <div className="container-detect-button">
          {isProgress === "upload" && (
            <button
              // style={
              //   props.detectItem.fileName === null ? { backgroundColor: "#383D48" } : { backgroundColor: "#00B48E" }
              // }
              onClick={handleDetect}
              disabled={props.detectItem.fileName === null ? true : false}
            >
              검증하기
            </button>
          )}
          {isProgress === "loading" && (
            <button style={{ backgroundColor: "#383D48", color: "#F6F7F8" }}>
              이전으로
            </button>
          )}
          {isProgress === "result" && (
            <button onClick={handleReDetect}>처음으로</button>
          )}
        </div>
      </div>
      {isProgress === "result" && (
        <DetectRightSideBar
          detectItem={props.detectItem}
          detectdata={detectdata}
        />
      )}
    </div>
  );
};

export default DetectMode;
