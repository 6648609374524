import React from "react";
import "../assets/scss/ToastMessage.scss";

interface Props {
  message: string;
}

const ToastMessage: React.FC<Props> = (props: Props) => {
  return (
    <div className="toastContainer">
      <div className="toastContainer-message">{props.message}</div>
    </div>
  );
};

export default ToastMessage;
