import Cookies from "universal-cookie";

const cookies =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("@electron/remote").session.defaultSession.cookies
    : new Cookies();

export const getToken = () => {
  if (process.env.REACT_APP_TARGET === "electron") {
    // cookies.set({ url: "http://localhost:3000", name: "accessToken", value: "" });
    let accessToken = "";
    cookies
      .get({ name: "accessToken" })
      .then(async (cookies: any) => {
        accessToken = await cookies[0].value;
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        return config;
      })
      .catch((error: any) => {
        console.log(error);
      });
    // const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    return config;
  } else {
    const token = cookies.get("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return config;
  }
};

export const getTokenCreate = () => {
  if (process.env.REACT_APP_TARGET === "electron") {
    // cookies.set({url: 'http://localhost:3000',name: 'token', value: ''})
    var token = "";
    cookies
      .get({ name: "accessToken" })
      .then((cookies: any) => {
        token = cookies[0].value;
        const config = {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
        };
        return config;
      })
      .catch((error: any) => {
        console.log(error);
      });
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
    };
    return config;
  } else {
    const token = cookies.get("token");
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
    };
    return config;
  }
};

// export const getToken = () => {
//   const token = cookies.get('token');
//   const config = {
//     headers: { Authorization: `Bearer ${token}` }
//   }
//   return config
// }
