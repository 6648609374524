export const EMBED_SCALE = "EMBED_SCALE" as const;
export const EMBED_SIZE = "EMBED_SIZE" as const;
export const EMBED_INIT = "EMBED_INIT" as const;
export const EMBED_ERROR = "EMBED_ERROR" as const;
export const EMBED_SUCCESS = "EMBED_SUCCESS" as const;
export const EMBED_CREATE_INIT = "EMBED_CREATE_INIT" as const;
export const EMBED_CREATE_ERROR = "EMBED_CREATE_ERROR" as const;
export const EMBED_CREATE_SUCCESS = "EMBED_CREATE_SUCCESS" as const;
export const EMBED_UPDATE_INIT = "EMBED_UPDATE_INIT" as const;
export const EMBED_UPDATE = "EMBED_UPDATE" as const;
export const EMBED_UPDATE_ERROR = "EMBED_UPDATE_ERROR" as const;
export const EMBED_UPDATE_SUCCESS = "EMBED_UPDATE_SUCCESS" as const;
export const EMBED_CLOSE_PREVIEW = "EMBED_CLOSE_PREVIEW" as const;
export const EMBED_OPEN_PREVIEW = "EMBED_OPEN_PREVIEW" as const;
export const EMBED_POSITION_XY = "EMBED_POSITION_XY" as const;
export const EMBED_CLOSE_COMPLETEMODAL = "EMBED_CLOSE_COMPLETEMODAL" as const;
export const EMBED_OPEN_COMPLETEMODAL = "EMBED_OPEN_COMPLETEMODAL" as const;

export function embedScale(payload: any) {
  return {
    type: EMBED_SCALE,
    payload,
  };
}

export function embedSize(payload: number, payload2: number) {
  return {
    type: EMBED_SIZE,
    payload,
    payload2,
  };
}

export function embedInit(payload: any) {
  return {
    type: EMBED_INIT,
    payload,
  };
}

export function embedError(error: any) {
  return {
    type: EMBED_ERROR,
    error,
  };
}

export function embedSuccess(payload: any) {
  return {
    type: EMBED_SUCCESS,
    payload,
  };
}
export function embedCreateInit(payload: any, payload2: any) {
  return {
    type: EMBED_CREATE_INIT,
    payload,
    payload2,
  };
}

export function embedCreateError(error: any) {
  return {
    type: EMBED_CREATE_ERROR,
    error,
  };
}

export function embedCreateSuccess(payload: any) {
  console.log("embed Create Success");
  return {
    type: EMBED_CREATE_SUCCESS,
    payload,
    // payload2,
  };
}

export function embedUpdateInit(payload: any, payload2: any, payload3: any) {
  return {
    type: EMBED_UPDATE_INIT,
    payload,
    payload2,
    payload3,
  };
}

export function embedUpdate() {
  return {
    type: EMBED_UPDATE,
  };
}

export function embedUpdateError(error: any) {
  return {
    type: EMBED_UPDATE_ERROR,
    error,
  };
}

export function embedUpdateSuccess(payload: any) {
  return {
    type: EMBED_UPDATE_SUCCESS,
    payload,
  };
}

export function embedClosePreview() {
  return {
    type: EMBED_CLOSE_PREVIEW,
  };
}

export function embedOpenPreview(payload: any) {
  return {
    type: EMBED_OPEN_PREVIEW,
    payload,
  };
}

export function embedCloseCompleteModal() {
  return {
    type: EMBED_CLOSE_COMPLETEMODAL,
  };
}

export function embedOpenCompleteModal(payload: any) {
  return {
    type: EMBED_OPEN_COMPLETEMODAL,
    payload,
  };
}

export function embedPostionXY(payload: number, payload2: number) {
  return {
    type: EMBED_POSITION_XY,
    payload,
    payload2,
  };
}

export type GetEmbedActionsType =
  | ReturnType<typeof embedInit>
  | ReturnType<typeof embedError>
  | ReturnType<typeof embedSuccess>
  | ReturnType<typeof embedCreateInit>
  | ReturnType<typeof embedCreateError>
  | ReturnType<typeof embedCreateSuccess>
  | ReturnType<typeof embedUpdateInit>
  | ReturnType<typeof embedUpdate>
  | ReturnType<typeof embedUpdateError>
  | ReturnType<typeof embedUpdateSuccess>
  | ReturnType<typeof embedScale>
  | ReturnType<typeof embedSize>
  | ReturnType<typeof embedClosePreview>
  | ReturnType<typeof embedOpenPreview>
  | ReturnType<typeof embedCloseCompleteModal>
  | ReturnType<typeof embedOpenCompleteModal>
  | ReturnType<typeof embedPostionXY>;
