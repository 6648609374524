import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducerType } from "../../modules";
import { useNavigate, useLocation } from "react-router-dom";

import ScreenHeader from "./ScreenHeader";
import ScreenContainer from "./ScreenContainer";
import Loading from "../../components/Loading";
import ErrorModal from "../../components/ErrorModal";
import RightSideBar from "./RightSideBar";

import "../../assets/scss/Embed.scss";
import Preview from "../Preview";
import BottomBar from "./BottomBar";
import ToastMessage from "../../components/ToastMessage";
import { detectReset } from "../../modules/Detect";
import CompleteModal from "../../components/CompleteModal";

type LocationProps = {
  state: {
    imgURL: string;
    file: any;
    isAdd: boolean;
    id: number;
    title: string;
    width: number;
    height: number;
    mode: string;
  };
};

const Embed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation() as unknown as LocationProps;

  const title = location.state.title;

  const EmbedState = useSelector((state: RootReducerType) => state.Embed);
  const { embedloading, data } = EmbedState;

  const [isLoading, setIsLoading] = useState(true);
  const [errorModal, setErrorModal] = useState({
    open: false,
    title: "",
    content: "",
  });

  const [panning, setPanning] = useState(false);
  const [rotate, setRotate] = useState<number>(0);

  const [toastState, setToastState] = useState(false);
  const [isAdd, setIsAdd] = useState<boolean>(true);

  const [testWidth, setTestWidth] = useState<number>(0);
  const [testHeight, setTestHeight] = useState<number>(0);

  useEffect(() => {
    if (toastState)
      setTimeout(() => {
        setToastState(false);
      }, 3000);
  }, [toastState]);

  //TODO 서버로딩
  useEffect(() => {
    if (embedloading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [embedloading]);

  //에러 팝업 확인 버튼 기능
  const handleTypeErrorModal = () => {
    dispatch(detectReset());
    navigate("/normal", {
      state: {
        sizeError: true,
        sizeErrorState: {
          open: true,
          title: "이미지 형식 오류",
          content:
            "현재는 지원하지 않는 형식입니다. 다른 이미지를 사용해 주세요",
        },
      },
    });
  };

  const handleImageSizeError = () => {
    dispatch(detectReset());
    navigate("/normal", {
      state: {
        sizeError: true,
        sizeErrorState: {
          open: true,
          title: "이미지 크기 오류",
          content: "500 x 500 ~ 3000 x 3000의 이미지를 넣어주세요",
        },
      },
    });
  };

  return (
    <>
      {isLoading && <Loading detect={false} />}
      <div className="embed-screen">
        <ScreenHeader title={title} mode={location.state.mode} />
        <div className="screen-container">
          <ScreenContainer
            handleImageSizeError={handleImageSizeError}
            handleTypeErrorModal={handleTypeErrorModal}
            panning={panning}
            setPanning={setPanning}
            rotate={rotate}
            image={location.state.imgURL}
            setTestWidth={setTestWidth}
            setTestHeight={setTestHeight}
            isAdd={location.state.isAdd}
          />
          <RightSideBar
            setToastState={setToastState}
            setIsAdd={setIsAdd}
            image={location.state.imgURL}
          />
        </div>
        <BottomBar
          panning={panning}
          setPanning={setPanning}
          setRotate={setRotate}
          rotate={rotate}
          isAdd={location.state.isAdd}
        />
        <ErrorModal
          title={errorModal.title}
          content={errorModal.content}
          errorModalOpen={errorModal.open}
          handleErrorModal={handleTypeErrorModal}
        />
        {toastState && <ToastMessage message="링크가 복사되었습니다" />}
        {/* {EmbedState.isPreviewOpen && <Preview />} */}
        {EmbedState.isCompleteModal && (
          <CompleteModal add={isAdd} mode={location.state.mode} />
        )}
      </div>
    </>
  );
};
export default Embed;
