import { ReactComponent as RefreshBtn } from "../../assets/icons/normal/refreshBtn.svg";
import { ReactComponent as GalleryImg } from "../../assets/icons/normal/gallery.svg";
import { ReactComponent as ThumbnailImg } from "../../assets/icons/normal/thumbnail.svg";
import { ReactComponent as ListImg } from "../../assets/icons/normal/list.svg";
import galleryTooltip from "../../assets/icons/normal/galleryTooltip.svg";
import viewTooltip from "../../assets/icons/normal/viewTooltip.svg";
import listTooltip from "../../assets/icons/normal/listTooltip.svg";
import refreshTooltip from "../../assets/icons/normal/refreshTooltip.svg";
import addImg from "../../assets/icons/normal/add.svg";
import print from "../../assets/icons/normal/print.svg";
import { Dispatch, RefObject, SetStateAction, useRef } from "react";

interface Props {
  viewMode: string;
  setViewMode: Dispatch<SetStateAction<string>>;
  trueCount: number;
  handleClickCreateFile: () => void;
  handleMultiDownload: (checkImage: any) => void;
  checkImage: any;
  CreateNormal: (event: any) => Promise<void>;
  CreateFileRef: RefObject<HTMLInputElement>;
  refreshData: () => void;
  handlePrintOut: (checkImg: any) => void;
}

const ContainerMenuBar = (props: Props) => {
  // const CreateFileRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className="menubar">
      <div className="menubar-toggle">
        <div className="toggle-btn">
          <div>
            <button onClick={() => props.setViewMode("gallery")}>
              <GalleryImg
                fill={props.viewMode === "gallery" ? "#F6F7F8" : "#737780"}
              />
              <img src={galleryTooltip} />
            </button>
            <button onClick={() => props.setViewMode("thumbnail")}>
              <ThumbnailImg
                fill={props.viewMode === "thumbnail" ? "#F6F7F8" : "#737780"}
              />
              <img src={viewTooltip} />
            </button>
            <button onClick={() => props.setViewMode("list")}>
              <ListImg
                fill={props.viewMode === "list" ? "#F6F7F8" : "#737780"}
              />
              <img src={listTooltip} />
            </button>
            <span />
            <button onClick={props.refreshData}>
              <RefreshBtn />
              <img src={refreshTooltip} />
            </button>
          </div>
        </div>
      </div>
      <div className="menubar-download">
        <button
          className={props.viewMode !== "gallery" ? "addBtn" : "none"}
          onClick={props.handleClickCreateFile}
        >
          <input
            type="file"
            name="CreateFile"
            ref={props.CreateFileRef}
            accept=" .jpg, .jpeg, .png"
            onChange={props.CreateNormal}
          />
          <img src={addImg} />
          추가하기
        </button>
        <button
          className={props.trueCount > 0 ? "printBtn active" : "printBtn"}
          onClick={() => props.handlePrintOut(props.checkImage)}
          disabled={props.trueCount === 0}
        >
          <img src={print} />
          프린트
        </button>
        <button
          className={props.trueCount > 0 ? "downloadBtn" : ""}
          onClick={() => props.handleMultiDownload(props.checkImage)}
          disabled={props.trueCount === 0}
        >
          다운로드
        </button>
      </div>
    </div>
  );
};

export default ContainerMenuBar;
