import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerType } from "../../modules";
import { ReactComponent as UrlOpen } from "../../assets/icons/embed/urlOpen.svg";
import { ReactComponent as UrlCopy } from "../../assets/icons/embed/urlCopy.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useImage from "use-image";

import {
  embedCreateInit,
  embedOpenCompleteModal,
  embedOpenPreview,
  embedScale,
  embedUpdate,
  embedUpdateInit,
  embedUpdateSuccess,
} from "../../modules/Embed";

import tooltip from "../../assets/icons/embed/tooltip.svg";
import { ReactComponent as Tooltipbar } from "../../assets/icons/embed/embed_tooltip.svg";
import { ReactComponent as SpotTooltipbar } from "../../assets/icons/embed/spot_tooltip.svg";
import Embed from ".";
import axios from "axios";
import { detectReset } from "../../modules/Detect";

interface Props {
  setToastState: (boolean: boolean) => void;
  setIsAdd: Dispatch<SetStateAction<boolean>>;
  image: string;
}

type LocationProps = {
  state: {
    imgURL: string;
    file: any;
    isAdd: boolean;
    id: any;
    title: string;
    width: number;
    height: number;
    embeddingMode: string;
    channel: string;
    colorSpace: string;
    link: string;
    alpha: number;
  };
};

const RightSideBar = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const [link, setLink] = useState(
    location.state.isAdd ? "https://snaptag.co.kr/" : location.state.link
  );
  const [changeLink, setChangeLink] = useState(link);
  const [linkModal, setLinkModal] = useState(false);
  const handleLink = (e: any) => {
    setChangeLink(e.target.value);
  };
  const [alphaStrength, setAlphaStrength] = useState(
    location.state.isAdd ? 60 : location.state.alpha
  );
  // const [modeOption, setModeOption] = useState(
  //   location.state.isAdd ? "CMYK" : location.state.colorSpace
  // );
  const [image, status] = useImage(location.state.imgURL);
  const [isTooltip, setIsTootip] = useState(false);
  const [isModeTooltip, setIsModeTootip] = useState<boolean>(false);
  const [isColorModeTooltip, setIsColorModeTootip] = useState<boolean>(false);
  const [imageCheck, setImageCheck] = useState<boolean>(true);
  const [userId, setUserId] = useState<string>("");
  const [userCode, setUserCode] = useState<string>("");

  useEffect(() => {
    if (props.image?.substring(0, 10) === "data:image") setImageCheck(true);
    else setImageCheck(false);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASIC_URI}/program/user/detail`)
      .then((data) => {
        // console.log(data.data);
        const userInfo = data.data;
        setUserId(userInfo.email);
        if (userInfo.email.length === 22) {
          setUserCode(`0${userInfo.email.slice(7, 8)}`);
        } else {
          setUserCode(userInfo.email.slice(7, 9));
        }
      });
  }, []);

  const linkHandler = (event: FormEvent) => {
    setLink(changeLink);
    setLinkModal(false);
  };

  const cancelHandler = (event: FormEvent) => {
    setChangeLink(link);
    setLinkModal(false);
  };

  const saveUrl = (url: string) => {
    if (url.substring(0, 4) === "http") {
      return url;
    } else {
      return "https://" + url;
    }
  };

  const openNewWindow = (url: string) => {
    const windowFeature = "width=1440, height=1024";
    const tmpUrl = saveUrl(url);
    window.open(tmpUrl, "_blank", windowFeature);
  };

  const copyToClipboard = (url: string) => {
    const tmp = document.createElement("input");
    document.body.appendChild(tmp);
    const tmpUrl = saveUrl(url);
    tmp.value = tmpUrl;
    tmp.select();
    document.execCommand("copy");
    document.body.removeChild(tmp);
    props.setToastState(true);
  };

  const handleExport = () => {
    if (location.state.isAdd) {
      console.log("Add Export");
      props.setIsAdd(true);
      const ImageFile = location.state.file;
      const formData = {
        // projectId: projectId,
        name: location.state.title,
        scale: 4,
        alpha: alphaStrength,
        embedding: imageCheck ? "v25" : "v20_W",
        channel: imageCheck ? "lab" : "y",
        colorSpace: "RGB",
        amount: 1,
        unit: 1,
        dpi: 300,
        // sourceImage: ImageFile,
        // width: image?.width,
        // height: image?.height,

        // isMass: false,
        // url: saveUrl(link),
        url: link,
      };

      const imageData = {
        title: location.state.title,
        url: link,
        alpha: alphaStrength,
        width: image?.width,
        height: image?.height,
        sourceImage: ImageFile,
        channel: imageCheck ? "lab" : "y",
        colorSpace: "RGB",
      };

      dispatch(embedCreateInit(formData, imageData));
    } else {
      console.log("Update Export");
      props.setIsAdd(false);
      let formData = {};
      // const formData = new FormData();
      // formData.append("alpha", `${options[embedOption].value}`);
      // formData.append("title", location.state.title);
      // formData.append("url", link);
      axios
        .get(
          `${process.env.REACT_APP_BASIC_URI}/program/product/${location.state.id}/detail`
        )
        .then((data) => {
          const imageData = data.data;
          console.log(data.data);
          formData = {
            // projectId: projectId,
            // scale: 4,
            // alpha: alphaStrength,
            // embedding: "v25",
            // channel: "lab",
            // amount: 1,
            // unit: 1,
            // dpi: 300,
            // width: image?.width,
            // height: image?.height,

            name: location.state.title,
            // description: link,
            // url: saveUrl(link),
            url: link,
            // urlPurchase: link,
            // urlCustom: link,
            // urlInstagram: link,
            embedding: imageData.channel === "y" ? "v20_W" : "v25",
            channel: imageData.channel,
            scale: 4,
            alpha: alphaStrength,
            dpi: 300,
            colorSpace: imageData.colorSpace,
          };
          setAlphaStrength(alphaStrength);
          setLink(imageData.url);
          dispatch(embedUpdate());
          return formData;
        })
        .then((formData) => {
          return axios
            .put(
              `${process.env.REACT_APP_BASIC_URI}/program/product/${location.state.id}`,
              formData
            )
            .then((data) => {
              dispatch(embedOpenCompleteModal(data.data));
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));

      // const formData = {
      //   // projectId: projectId,
      //   // scale: 4,
      //   // alpha: alphaStrength,
      //   // embedding: "v25",
      //   // channel: "lab",
      //   // amount: 1,
      //   // unit: 1,
      //   // dpi: 300,
      //   // width: image?.width,
      //   // height: image?.height,

      //   title: location.state.title,
      //   description: link,
      //   // url: saveUrl(link),
      //   url: `https://program-detectpage.netlify.app?id=${userId}`,
      //   // urlPurchase: link,
      //   // urlCustom: link,
      //   // urlInstagram: link,
      //   embedding: location.state.channel === "y" ? "v20_W" : "v25",
      //   channel: location.state.channel,
      //   scale: 4,
      //   alpha: alphaStrength,
      //   dpi: 300,
      //   colorSpace: "RGB",
      // };
      // dispatch(embedUpdate());

      // axios
      //   .put(
      //     `${process.env.REACT_APP_BASIC_URI}/program/product/${location.state.id}`,
      //     formData
      //   )
      //   .then((data) => {
      //     const updateData = data.data.products;
      //     setAlphaStrength(updateData.alpha);
      //     setLink(updateData.url);
      //     dispatch(embedOpenCompleteModal(data.data));
      //   });
    }
  };

  // console.log(location.state, "log");

  return (
    <div className="screen-right-sideBar">
      <div className="sub-container">
        <div className="sub-container-title">트랜스폼</div>
        <div className="sub-container-content">
          <div className="sub-container-subcontent">
            <div className="sub-container-subcontent-title">W</div>
            <div className="sub-container-subcontent-value">
              {/* {((Number(image?.width) * 25.4) / 300).toFixed(2)}
              <div>mm</div> */}
              {image?.width}
            </div>
          </div>
          <div className="sub-container-subcontent">
            <div className="sub-container-subcontent-title">H</div>
            <div className="sub-container-subcontent-value">
              {/* {((Number(image?.height) * 25.4) / 300).toFixed(2)}
              <div>mm</div> */}
              {image?.height}
            </div>
          </div>
        </div>
      </div>
      <div className="sub-container">
        <div className="sub-container-title">링크</div>
        <div className="setting-item">
          <button
            onClick={() => setLinkModal(!linkModal)}
            style={link ? { color: "#B3B8C0" } : { color: "#737780" }}
          >
            {link ? link : "링크값을 입력해주세요"}
          </button>
          {linkModal && (
            <div className="setting-modal">
              <input
                value={changeLink || ""}
                onChange={(event) => handleLink(event)}
                placeholder="링크값을 입력해주세요"
              />
              <div>
                <button onClick={cancelHandler}>취소</button>
                <button
                  onClick={linkHandler}
                  // disabled={changeLink === "" ? true : false}
                >
                  저장하기
                </button>
              </div>
            </div>
          )}
          <button
            disabled={link === "" ? true : false}
            onClick={() => openNewWindow(link)}
          >
            <UrlOpen />
          </button>
          <button
            disabled={link === "" ? true : false}
            onClick={() => copyToClipboard(link)}
          >
            <UrlCopy />
          </button>
        </div>
      </div>
      <div className="sub-container">
        <div className="sub-container-title">
          <span>기술 세기</span>
          <button
            className="alphaTooltip"
            onClick={() => setIsTootip(!isTooltip)}
          >
            <img src={tooltip} alt="tooltipImage" />
          </button>
          {isTooltip && (
            <div
              className={
                location.state.embeddingMode === "normal"
                  ? "embed-tooltipbar"
                  : "embed-spottooltipbar"
              }
              onClick={() => setIsTootip(!isTooltip)}
            >
              <Tooltipbar />
            </div>
          )}
        </div>
        <div className="sub-container-optioncontent">
          <button
            onClick={() => setAlphaStrength(4)}
            className={alphaStrength === 4 ? "active" : ""}
          >
            1
          </button>
          <button
            onClick={() => setAlphaStrength(8)}
            className={alphaStrength === 8 ? "active" : ""}
          >
            2
          </button>
          <button
            onClick={() => setAlphaStrength(12)}
            className={alphaStrength === 12 ? "active" : ""}
          >
            3
          </button>
          <button
            onClick={() => setAlphaStrength(16)}
            className={alphaStrength === 16 ? "active" : ""}
          >
            4
          </button>
          <button
            onClick={() => setAlphaStrength(20)}
            className={alphaStrength === 20 ? "active" : ""}
          >
            5
          </button>
          {/* <button
            onClick={() => setAlphaStrength(14)}
            className={alphaStrength === 14 ? "active" : ""}
          >
            6
          </button>
          <button
            onClick={() => setAlphaStrength(16)}
            className={alphaStrength === 16 ? "active" : ""}
          >
            7
          </button> */}
        </div>
      </div>
      <div className="sub-container last">
        <div className="sub-container-export" onClick={handleExport}>
          {location.state.isAdd ? "랩코드 적용" : "수정하기"}
        </div>
      </div>
    </div>
  );
};
export default RightSideBar;
