import React, { useEffect } from "react";
import "../assets/scss/reset.scss";
import "../assets/scss/Components.scss";

interface Props {
  title: string;
  content: any;
  errorModalOpen: boolean;
  handleErrorModal: any;
}

const ErrorModal = (props: Props) => {
  useEffect(() => {
    window.focus();
  }, []);

  return (
    <div className="modal-shadow" style={{ display: props.errorModalOpen ? "flex" : "none" }} key="normal-page-error">
      <div className="modal">
        <div className="modal-background">
          <div className="modal-title">{props.title}</div>
          <div className="modal-content">{props.content}</div>
        </div>
        <div className="modal-actions">
          <div id="error-exit" className="modal-actions-export" onClick={() => props.handleErrorModal(props.title)}>
            확인
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
