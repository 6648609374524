interface Props {
  isProgress: string;
}

const LoadingSVG = (props: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill={props.isProgress === "loading" ? "#00B48E" : "#9EA3AA"} />
      <path
        d="M7.04027 12.356C7.31227 12.14 7.43627 12.04 7.41227 12.056C8.19627 11.408 8.81227 10.876 9.26027 10.46C9.71627 10.044 10.1003 9.608 10.4123 9.152C10.7243 8.696 10.8803 8.252 10.8803 7.82C10.8803 7.492 10.8043 7.236 10.6523 7.052C10.5003 6.868 10.2723 6.776 9.96827 6.776C9.66427 6.776 9.42427 6.892 9.24827 7.124C9.08027 7.348 8.99627 7.668 8.99627 8.084H7.01627C7.03227 7.404 7.17627 6.836 7.44827 6.38C7.72827 5.924 8.09227 5.588 8.54027 5.372C8.99627 5.156 9.50027 5.048 10.0523 5.048C11.0043 5.048 11.7203 5.292 12.2003 5.78C12.6883 6.268 12.9323 6.904 12.9323 7.688C12.9323 8.544 12.6403 9.34 12.0563 10.076C11.4723 10.804 10.7283 11.516 9.82427 12.212H13.0643V13.88H7.04027V12.356Z"
        fill={props.isProgress === "loading" ? "#F6F7F8" : "#555963"}
      />
    </svg>
  );
};

export default LoadingSVG;
