import * as React from "react";
import "../assets/scss/reset.scss";
import "../assets/scss/Components.scss";
// import Lottie from 'react-lottie'
import loading from "../utils/Lottie/Lodding.json";

interface Props {
  detect: boolean;
}

const Loading = (props: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return props.detect ? (
    <div className="detectLoading">
      <div className="detectLoading-img">
        <div className="dots">
          <div className="exportImg" />
        </div>
      </div>
      <div className="detectLoading-logo"></div>
    </div>
  ) : (
    <div className="loading">
      <div className="loading-img">
        <div className="dots">
          <div className="exportImg" />
        </div>
      </div>
      <div className="loading-logo"></div>
    </div>
  );
};

export default Loading;
