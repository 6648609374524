import { put, all, call, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import * as actionTypes from "./actions";
import Cookies from "universal-cookie";

const cookies =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("@electron/remote").session.defaultSession.cookies
    : new Cookies();

// const REACT_APP_BASIC_URI_TEST="https://dev.admin.program.labcode.kr/api/v1/"
// const REACT_APP_BASIC_URI: string = `${process.env.REACT_APP_BASIC_URI}user` as string;
const REACT_APP_BASIC_URI: string =
  `${process.env.REACT_APP_BASIC_URI}` as string;

//TODO 로그인
function* login(payload: any) {
  try {
    // "https://dev.admin.program.general.labcode.kr/login"
    //TODO 서버 연동
    // const token: AxiosResponse =
    console.log(payload.payload);
    const token: AxiosResponse = yield call(() =>
      axios
        .post(
          `${REACT_APP_BASIC_URI}/program/auth/login`,
          {
            email: payload.payload.email,
            password: payload.payload.password,
          },
          {
            withCredentials: true,
            headers: { "Content-Type": `application/json` },
          }
        )
        .then((response) => {
          const { accessToken, refreshToken } = response.data;
          // accessToken = response.data.ac;
          // cookies.set({ url: "http://localhost:3000", name: "accessToken", value: accessToken });
          // cookies.set({ url: "http://localhost:3000", name: "refreshToken", value: refreshToken });

          // actionTypes.loginRequest({ accessToken, refreshToken });

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;

          return { accessToken, refreshToken };
        })
    );

    yield put(actionTypes.loginSuccess(token));
    console.log("Login Success at sagas.ts");
  } catch (err: any) {
    // alert("이메일 또는 비밀번호를 다시 한번 확인해주세요.");
    console.log(err);
    yield put(actionTypes.loginFailure("LOGIN_FAILURE"));
  }
}

//TODO 로그아웃
function* logout() {
  try {
    yield put(actionTypes.logoutSuccess());
    cookies.remove("http://localhost:3000", "refreshToken");
    cookies.remove("http://localhost:3000", "accessToken");
    //TODO 쿠키 제거
  } catch (err: any) {
    console.log(err);
    yield put(actionTypes.logoutFailure("LOGOUT_FAILURE"));
  }
}

export function* getLoginSaga() {
  yield all([
    takeLatest(actionTypes.LOGIN_REQUEST, login),
    takeLatest(actionTypes.LOGOUT_REQUEST, logout),
  ]);
}
