import React, { useState, useEffect } from "react";
import "../../assets/scss/reset.scss";
import "../../assets/scss/Books.scss";
import { Check, FileDownload } from "@mui/icons-material";
import Axios from "axios";
import DownloadModal from "../../components/DownloadModal";
import { ReactComponent as UpdateBtn } from "../../assets/icons/normal/updateBtn.svg";
import { ReactComponent as LinkOpen } from "../../assets/icons/normal/linkopen.svg";
// import { ReactComponent as LinkCopy } from "../../assets/icons/normal/galleryLinkCopy.svg";
import useImage from "use-image";
import { useLocation } from "react-router-dom";
import PdfView from "../../components/PdfView";

interface Props {
  item: any;
  index: number;
  handleClickNormal: any;
  handleClickUpdataFile: any;
  handleCheckImage: any;
  UpdataFileRef: any;
  title: any;
  isChecked: boolean;
  setToastState: any;
  setStopDownload: any;
}

// type LocationProps = {
//   state: {
//     // imgURL: string;
//     // file: any;
//     // isAdd: boolean;
//     // id: any;
//     title: string;
//     width: number;
//     height: number;
//   };
// };

const Container = (props: Props) => {
  // const location = useLocation() as unknown as LocationProps;
  const [productTitle, setProductTitle] = useState("");
  const [isInputState, setIsInputState] = useState(false);
  const [modal, setModal] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    imgCount: 0,
    curCount: 0,
  });
  const [image, status] = useImage(props.item.sourceImageUrl);

  const mode = process.env.REACT_APP_MODE === "app";

  useEffect(() => {
    setProductTitle(props.title);
  }, []);

  const handleCheckImage = (e: React.FormEvent<HTMLFormElement>) => {
    props.handleCheckImage(props.index);
    e.stopPropagation();
  };

  const alphaValue = (alpha: number): number => {
    const value: any = {
      4: 1,
      8: 2,
      12: 3,
      16: 4,
      20: 5,
    };
    return value[alpha] ?? 0;
  };

  const dateStringCutter = (value: string) => {
    var hours = Number(value.slice(11, 13));
    var ampm = hours >= 12 ? "오후" : "오전";
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${value.slice(0, 4)}.${value.slice(5, 7)}.${value.slice(8, 10)}`;
  };

  const modalHandler = () => {
    setModal(false);
    setDownloadInfo({
      progress: 0,
      loaded: 0,
      total: 0,
      completed: false,
      imgCount: 0,
      curCount: 0,
    });
  };

  const copyToClipboard = (url: string, event: any) => {
    event.stopPropagation();
    // event.preventDefualt();
    const tmp = document.createElement("input");
    document.body.appendChild(tmp);
    tmp.value = url;
    tmp.select();
    document.execCommand("copy");
    document.body.removeChild(tmp);
    props.setToastState(true);
  };

  const openNewWindow = (url: string, event: any) => {
    event.stopPropagation();
    // event.preventDefualt();
    const windowFeature = "width=1440, height=1024";
    window.open(url, "_blank", windowFeature);
  };

  // console.log(props.item);

  return (
    <>
      {modal && (
        <DownloadModal
          title={props.title}
          downloadInfo={downloadInfo}
          modalHandler={modalHandler}
          setStopDownload={props.setStopDownload}
        />
      )}
      <div
        className={props.isChecked ? "container-card active" : "container-card"}
        onClick={(e: any) => handleCheckImage(e)}
      >
        <div style={{ height: "261.5px" }}>
          <img
            src={props.item?.sourceImageUrl}
            style={{ height: "100%", width: "100%" }}
            alt={`card-mage_${props.item?.id}`}
          />
          {/* {props.item.fileFormat === "jpg" ? (
            <img
              src={props.item?.sourceImageUrl}
              style={{ height: "100%", width: "100%" }}
              alt={`card-mage_${props.item?.id}`}
            />
          ) : (
            <PdfView pdf={props.item?.sourceImage} />
          )} */}
          <div
            className="container-card-shadow"
            // onClick={() =>
            //   props.handleClickNormal(
            //     props.item.id,
            //     props.item.labcodeImage,
            //     false,
            //     props.item.width,
            //     props.item.height,
            //     productTitle,
            //     props.item.channel,
            //     props.item.colorSpace,
            //     props.item.url,
            //     props.item.alpha
            //   )
            // }
          >
            <div className="iocnGraup">
              <div className="iocnGraup-btn">
                <button
                  onClick={() =>
                    props.handleClickNormal(
                      props.item.id,
                      props.item.sourceImageUrl,
                      false,
                      props.item.width,
                      props.item.height,
                      productTitle,
                      props.item.channel,
                      props.item.colorSpace,
                      props.item.url,
                      props.item.alpha,
                      "gallery"
                    )
                  }
                >
                  <UpdateBtn className="updateHover" />
                </button>
              </div>
            </div>
            <div className="container-card-shadow-description">
              <div>생성일 : {dateStringCutter(props.item.created)}</div>
              <div>종류 : {props.item.fileFormat}</div>
              <div>
                규격 : {image?.width} x {image?.height}
                {/* {((Number(image?.width) * 25.4) / 300).toFixed(2)}mm x
                {((Number(image?.height) * 25.4) / 300).toFixed(2)}mm */}
              </div>
              <div>기술세기 : {alphaValue(props.item.alpha)}</div>
            </div>
          </div>
        </div>
        <div className="container-card-title">
          <div> {productTitle}</div>
          <div>
            <LinkOpen
              className="linkOpenHover"
              onClick={(event) => openNewWindow(props.item.url, event)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Container);
