import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../assets/icons/embed/leftArrow.svg";
import { detectReset } from "../../modules/Detect";

interface Props {
  title: string;
  mode: string;
}
const ScreenHeader = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetHandler = () => {
    dispatch(detectReset());
    navigate("/normal", {
      state: {
        mode: props.mode,
      },
    });
  };

  return (
    <div className="screen">
      <div className="header">
        <div className="header-left">
          <div className="homeBtn">
            <button className="btn" onClick={resetHandler}>
              <LeftArrow />
            </button>
          </div>
          {/* <div className="flex-center">
            <button className="header-button tooltip-btn" onClick={() => navigate("/normal")}>
              <div className="home" />
              <div className="tooltip tooltip-left" style={{ top: 42, right: -30 }}>
                홈 이동
              </div>
            </button>
          </div> */}
        </div>
        <div className="header-center">{props.title}</div>
        <div className="header-right"></div>
      </div>
    </div>
  );
};
export default ScreenHeader;
