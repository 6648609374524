import "../assets/scss/reset.scss";
import "../assets/scss/Components.scss";

interface Props {
  title: string;
  content: string;
  isOpen: boolean;
  handleDeleteModal: any;
  deleteFunction: any;
}

const DeleteModal = (props: Props) => {
  return (
    <div className="modal-shadow" style={{ display: props.isOpen ? "flex" : "none" }}>
      <div className="modal">
        <div className="modal-background">
          <div className="modal-title">{props.title}</div>
          <div className="modal-content">{props.content}</div>
        </div>
        <div className="modal-actions">
          <div className="modal-actions-close" onClick={props.handleDeleteModal}>
            취소
          </div>
          <div className="modal-actions-confirm" onClick={props.deleteFunction}>
            삭제
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
