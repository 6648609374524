import { Dispatch, RefObject, SetStateAction, useState } from "react";
import Loading from "../../components/Loading";
import failureLg from "../../assets/icons/normal/failure_lg.svg";
import plus from "../../assets/icons/normal/plus.svg";
import certLg from "../../assets/icons/normal/cert_lg.svg";

interface Props {
  detectItem: {
    item: string | null;
    file: File | null;
    fileName: string | null;
  };
  detectdata: any;
  handleUpdateDetect: () => void;
  handleDeleteDetect: () => void;
  UpdateDetectFileRef: RefObject<HTMLInputElement>;
  UpdateDetect: (event: any) => void;
  handleClickDetectFile: any;
  DetectFileRef: RefObject<HTMLInputElement>;
  CreateDetect: (event: any) => void;
  detectloading: boolean;
  isProgress: string;
  setDetectItem: Dispatch<
    SetStateAction<{
      item: string | null;
      file: File | null;
      fileName: string | null;
    }>
  >;
}

const DetectContainer = (props: Props) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const onDrop = (event: any) => {
    if (!event.dataTransfer.items[0]) return;
    if (!event.dataTransfer.files[0]) return;
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      props.setDetectItem({
        item: reader.result as string,
        file: file,
        fileName: file.name,
      });
    };
    setIsDragOver(false);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const onDragLeave = (event: any) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const onDragEnd = (event: any) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const imageContainer = (isProgress: string) => {
    if (isProgress === "result") {
      if (
        props.detectdata === null ||
        props.detectdata.message === "DATA_NONE"
      ) {
        return (
          // <div className="container-detect-card-shadow">
          <div className="bigcert">
            <img src={failureLg} />
          </div>
          // </div>
        );
      } else {
        return (
          // <div className="container-detect-card-shadow">
          <div className="bigcert">
            <img src={certLg} />
          </div>
          // </div>
        );
      }
    } else {
      return (
        <div className="container-detect-card-shadow">
          <div className="iocnGraup">
            <div className="description flex-center">
              <div className="photo" onClick={props.handleUpdateDetect} />
              <input
                type="file"
                name="UpdateFile"
                ref={props.UpdateDetectFileRef}
                accept=" .jpg, .jpeg, .png"
                onChange={props.UpdateDetect}
              />
            </div>
            <div className="delete flex-center">
              <div className="trash" onClick={props.handleDeleteDetect} />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {props.detectItem.item === null ? (
        <div className="cardWrapper" onDrop={onDrop}>
          <div
            className={
              isDragOver
                ? "container-detect-card createFile active"
                : "container-detect-card createFile"
            }
            onClick={props.handleClickDetectFile}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDragEnd={onDragEnd}
          >
            <div className="container-detect-card-img">
              <img src={plus} />
            </div>
            <div className="container-detect-card-text1">
              기술을 확인하려면 여기에 이미지를 올려주세요!
            </div>
            <div className="container-detect-card-text2">
              또는 여기를 클릭하여 이미지를 업로드해주세요
            </div>
          </div>
          <input
            type="file"
            name="CreateFile"
            ref={props.DetectFileRef}
            accept=" .jpg, .jpeg, .png"
            onChange={props.CreateDetect}
          />
        </div>
      ) : (
        <div className="container-detect-card">
          <>
            <div className="container-detect-card-img2">
              {props.detectloading && <Loading detect={true} />}
              <img
                className="detectImg"
                src={props.detectItem.item}
                alt="detectImage"
              />
            </div>
            {imageContainer(props.isProgress)}
          </>
        </div>
      )}
    </>
  );
};

export default DetectContainer;
