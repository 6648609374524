import { put, all, call, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import * as actionTypes from "./actions";
import { getToken } from "../../utils/Cookies/Cookies";
import Cookies from "universal-cookie";

const REACT_APP_PRODUCT_URI: string =
  `${process.env.REACT_APP_BASIC_URI}/program` as string;
const REACT_APP_BOOK_URI: string = `https://admin.snaptag.co.kr/v1/sa/products/codes?page=1&pageSize=200&industryCode=3`;

//TODO 북 리스트 가져오기
function* productCallWorker() {
  // const config = getToken();
  const cookies = new Cookies();

  const config = cookies.get("accessToken");
  try {
    const books: AxiosResponse = yield call(
      () =>
        axios.get(
          `${REACT_APP_PRODUCT_URI}/product?page=1&pageSize=500`
          // `${REACT_APP_PRODUCT_URI}/list/?ordering=DESC`,
          // config
        )
      //${REACT_APP_PRODUCT_URI}/list/?ordering=DESC`, config)
    );

    yield put(actionTypes.productSuccess(books.data));
  } catch (err: any) {
    console.log(err, "product err");
    yield put(actionTypes.productError(err));
  }
}

//TODO 북 가져오기
function* productCallIdWorker(payload: any) {
  const config = getToken();
  try {
    const productId: AxiosResponse = yield call(() =>
      axios.get(`${REACT_APP_PRODUCT_URI}/list/${payload.payload}`, config)
    );
    yield put(actionTypes.productIdSuccess(productId.data));
  } catch (err: any) {
    console.log(err);
    yield put(actionTypes.productIdError(err));
  }
}

//TODO 북 생성
function* productCreateWorker({ payload }: { payload: any }) {
  const config = getToken();
  try {
    yield call(() =>
      axios.post(`${REACT_APP_PRODUCT_URI}/create`, payload, config)
    );
    const products: AxiosResponse = yield call(() =>
      axios.get(`${REACT_APP_PRODUCT_URI}/list/?ordering=DESC`, config)
    );
    console.log(products.data);
    yield put(actionTypes.productSuccess(products.data));
  } catch (err: any) {
    console.log(err);
    yield put(actionTypes.productCreateError(err));
  }
}

//TODO 북 업데이트
function* productUpdateWorker(payload: any) {
  const config = getToken();
  try {
    let updateJson: any;
    let parameter: any;
    if (payload.payload.image_cover) {
      updateJson = { image_cover: payload.payload.image_cover };
      parameter = "image-cover";
    } else if (payload.payload.title) {
      updateJson = { title: payload.payload.title };
      parameter = "title";
    }
    yield call(() =>
      axios.patch(
        `${REACT_APP_PRODUCT_URI}/${payload.payload.id}/${parameter}`,
        updateJson,
        config
      )
    );

    const products: AxiosResponse = yield call(() =>
      axios.get(`${REACT_APP_PRODUCT_URI}?ordering=DESC`, config)
    );
    yield put(actionTypes.productSuccess(products.data));
  } catch (err: any) {
    console.log(err, "2");
    yield put(actionTypes.productUpdateError(err));
  }
}

//TODO 북 제거
function* productDeleteWorker(payload: any) {
  const config = getToken();
  try {
    yield call(() =>
      axios.delete(`${REACT_APP_PRODUCT_URI}/${payload.payload.id}`, config)
    );
    const products: AxiosResponse = yield call(() =>
      axios.get(`${REACT_APP_PRODUCT_URI}?ordering=DESC`, config)
    );
    yield put(actionTypes.productSuccess(products.data));
  } catch (err: any) {
    yield put(actionTypes.productDeleteError(err));
  }
}

export function* getNormalSaga() {
  yield all([
    takeLatest(actionTypes.PRODUCT_PAGE_INIT, productCallWorker),
    takeLatest(actionTypes.PRODUCT_ID_INIT, productCallIdWorker),
    takeLatest(actionTypes.PRODUCT_CREATE_INIT as any, productCreateWorker),
    //TODO 타입 오버라이딩 에러로 any 타입 삽입
    takeLatest(actionTypes.PRODUCT_UPDATE_INIT, productUpdateWorker),
    takeLatest(actionTypes.PRODUCT_DELETE_INIT, productDeleteWorker),
  ]);
}
