import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stage, Layer, Image, Line } from "react-konva";
import Loading from "../../components/Loading";
import useImage from "use-image";
import { embedScale } from "../../modules/Embed";
import { useLocation } from "react-router-dom";
// import "../../assets/scss/Preview.scss";

const KonvaCanvasdraw = (props) => {
  const dispatch = useDispatch();
  const testImg = useSelector((state) => state.Embed.data);
  // const [image, status] = useImage(props.imgURL || testImg);
  const [[imgWidth, imgHeight], setImgSize] = useState([0, 0]);
  const [zoom, setZoom] = useState(false);
  // const [panning, setPanning] = useState(false);
  const imgRef = useRef();
  const stageRef = useRef();
  const konvaStage = document.getElementById("PreviewStage");

  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth - 115);
  const [canvasHeight, setCanvasHeight] = useState(
    window.innerHeight - (72 + 68 + 48)
  );
  const scale = useSelector((state) => state.Embed.scale);
  const location = useLocation();
  const [image, status] = useImage(props.imgURL);

  useEffect(() => {
    setImgSize([image?.width, image?.height]);
    // return () => setScale(1);
  }, [image]);

  console.log(image);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    window.addEventListener("keyup", handleUserKeyUp);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
      window.removeEventListener("keyup", handleUserKeyUp);
      // setScale(1);
    };
  });

  const handleUserKeyPress = (event) => {
    event.stopPropagation();
    const { key, keyCode } = event;
    if (!event) return;
    if (keyCode === 17) setZoom(true);
    else if (keyCode === 32) props.setPanning(true);
  };

  const handleUserKeyUp = (event) => {
    event.stopPropagation();
    const { key, keyCode } = event;
    if (!event) return;
    if (keyCode === 17) setZoom(false);
    else if (keyCode === 32) props.setPanning(false);
  };

  useEffect(() => {
    if (imgRef && imgRef.current && props.panning) {
      imgRef.current.addEventListener("mousedown", mouseDownHandler, false);
    }

    return () => {
      imgRef.current?.removeEventListener("mousedown", mouseDownHandler, false);
    };
  });

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const mouseDownHandler = (event) => {
    event.stopPropagation();
    pos = {
      left: konvaStage.scrollLeft,
      top: konvaStage.scrollTop,
      x: event.clientX,
      y: event.clientY,
    };
    imgRef.current.addEventListener("mousemove", mouseMoveHandler);
    imgRef.current.addEventListener("mouseup", mouseUpHandler);
    konvaStage.addEventListener("mouseleave", mouseUpHandler);
  };

  const mouseMoveHandler = function (event) {
    konvaStage.style.cursor = "grabbing";
    const dx = event.clientX - pos.x;
    const dy = event.clientY - pos.y;

    konvaStage.scrollTop = pos.top - dy;
    konvaStage.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    konvaStage.style.cursor = "grab";
    konvaStage.style.removeProperty("user-select");
    imgRef.current.removeEventListener("mousemove", mouseMoveHandler);
    imgRef.current.removeEventListener("mouseup", mouseUpHandler);
    konvaStage.removeEventListener("mouseleave", mouseUpHandler);
  };

  const handleOnWheel = (e, handleSetScale) => {
    if (zoom) {
      if (e.evt.deltaY >= 0) {
        handleSetScale("+");
      } else {
        handleSetScale("-");
      }
    }
  };

  // const handleSetScale = (type) => {
  //   if (type === "+") {
  //     if (scale < 2) {
  //       dispatch(embedScale(Number(scale) + 0.1));
  //     }
  //   } else {
  //     if (scale >= 0.2) {
  //       dispatch(embedScale(Number(scale) - 0.1));
  //     }
  //   }
  // };
  const handleSetScale = (type) => {
    if (type === "+") {
      if (props.scale < 2) {
        props.setScale((prev) => prev + 0.1);
      }
    } else {
      if (props.scale > 0.2) {
        props.setScale((prev) => prev - 0.1);
      }
    }
  };

  // if (status === "loading") {
  //   return <Loading />;
  // } else {

  console.log(image, "previewKonva");

  return (
    <div className="PreviewStage" id="PreviewStage">
      <Stage
        x={0}
        y={0}
        // width={imgWidth > canvasWidth ? imgWidth * props.scale : imgWidth}
        // height={imgHeight > canvasHeight ? imgHeight * props.scale : imgHeight}
        width={imgWidth * (scale - 0.1)}
        height={imgHeight * (scale - 0.1)}
      >
        <Layer>
          <Image
            id="mainImg"
            image={image}
            listening={true}
            x={(imgWidth * (scale - 0.1)) / 2}
            y={(imgHeight * (scale - 0.1)) / 2}
            offsetX={(imgWidth * (scale - 0.1)) / 2}
            offsetY={(imgHeight * (scale - 0.1)) / 2}
            width={imgWidth * (scale - 0.1)}
            height={imgHeight * (scale - 0.1)}
            // onWheel={(e) => handleOnWheel(e, handleSetScale)}
            ref={imgRef}
            rotation={props.rotate}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default KonvaCanvasdraw;
