interface Props {
  isProgress: string;
}

const ResultSVG = (props: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill={props.isProgress === "result" ? "#00B48E" : "#9EA3AA"} />
      <path
        d="M6.97919 7.712C7.01119 6.856 7.29119 6.196 7.81919 5.732C8.34719 5.268 9.06319 5.036 9.96719 5.036C10.5672 5.036 11.0792 5.14 11.5032 5.348C11.9352 5.556 12.2592 5.84 12.4752 6.2C12.6992 6.56 12.8112 6.964 12.8112 7.412C12.8112 7.94 12.6792 8.372 12.4152 8.708C12.1512 9.036 11.8432 9.26 11.4912 9.38V9.428C11.9472 9.58 12.3072 9.832 12.5712 10.184C12.8352 10.536 12.9672 10.988 12.9672 11.54C12.9672 12.036 12.8512 12.476 12.6192 12.86C12.3952 13.236 12.0632 13.532 11.6232 13.748C11.1912 13.964 10.6752 14.072 10.0752 14.072C9.11519 14.072 8.34719 13.836 7.77119 13.364C7.20319 12.892 6.90319 12.18 6.87119 11.228H8.86319C8.87119 11.58 8.97119 11.86 9.16319 12.068C9.35519 12.268 9.63519 12.368 10.0032 12.368C10.3152 12.368 10.5552 12.28 10.7232 12.104C10.8992 11.92 10.9872 11.68 10.9872 11.384C10.9872 11 10.8632 10.724 10.6152 10.556C10.3752 10.38 9.98719 10.292 9.45119 10.292H9.06719V8.624H9.45119C9.85919 8.624 10.1872 8.556 10.4352 8.42C10.6912 8.276 10.8192 8.024 10.8192 7.664C10.8192 7.376 10.7392 7.152 10.5792 6.992C10.4192 6.832 10.1992 6.752 9.91919 6.752C9.61519 6.752 9.38719 6.844 9.23519 7.028C9.09119 7.212 9.00719 7.44 8.98319 7.712H6.97919Z"
        fill={props.isProgress === "result" ? "#F6F7F8" : "#555963"}
      />
    </svg>
  );
};

export default ResultSVG;
