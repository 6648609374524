import { put, all, call, takeLatest, take } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import * as actionTypes from "./actions";
import * as detectActionTypes from "../Detect/actions";
import { getToken, getTokenCreate } from "../../utils/Cookies/Cookies";
import Cookies from "universal-cookie";

const remote =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("@electron/remote")
    : null;

const REACT_APP_BASIC_URI: string =
  `${process.env.REACT_APP_BASIC_URI}/program/product` as string;

// const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
//TODO page 리스트 가져오기(PDF가 서버에 있을 경우)
//TODO PDF 삽입 (multipart/form-data)

//TODO PDF 삽입 (multipart/form-data)
function* embedCreateWorker(payload: any) {
  const config = getToken();
  try {
    const sourceImage = payload.payload2.sourceImage;
    const width = payload.payload2.width;
    const height = payload.payload2.height;
    const EMBED: AxiosResponse = yield call(() =>
      axios
        .post(`${REACT_APP_BASIC_URI}/before-embedding`, payload.payload, {
          headers: {
            Accept: "application/json",
            // "Content-Type": `multipart/form-data`,
          },
        })
        .then((data) => {
          // console.log(data);
          return axios
            .post(
              `${REACT_APP_BASIC_URI}/embedding`,
              {
                sourceImage,
                // width,
                // height,
                productId: data.data.id,
              },
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": `multipart/form-data`,
                },
              }
            )
            .then((data) => {
              console.log(data);
              return data.data;
            })
            .catch((err) => {
              console.log(err);
              // axios.delete(`${REACT_APP_BASIC_URI}/${data.data.productId}`);
            });
        })
    );
    console.log(EMBED);
    yield put(actionTypes.embedOpenCompleteModal(EMBED));
    return EMBED;
  } catch (err: any) {
    console.log(err);
    yield put(actionTypes.embedCreateError(err));
  }
}

function* embedUpdateWorker(payload: any) {
  const config = getTokenCreate();
  try {
    const EMBED: AxiosResponse = yield call(
      () =>
        axios.put(
          `${REACT_APP_BASIC_URI}/${payload.payload2}`,
          payload.payload
          // config
        )
      // .then((data) => {
      //   console.log(data.data);
      //   actionTypes.embedUpdateSuccess(data.data);
      // })
    );
    yield put(actionTypes.embedOpenCompleteModal(EMBED.data));
    // yield put(detectActionTypes.detectReset());
    // yield put(actionTypes.embedOpenPreview(EMBED.data));
    // window.open(EMBED.data.labcodeImage, EMBED.data.title)
    // childWindow?.document.write('<img src={EMBED.data.labcodeImage}>')
    // const labcodeURL = EMBED.data.labcodeImage;
    // const title = payload.payload3;
    // remote.getGlobal("openSub")([labcodeURL, title]);
    return EMBED;
  } catch (err: any) {
    console.log(err);
    yield put(actionTypes.embedUpdateError(err));
  }
}

export function* getEmbedSaga() {
  yield all([
    takeLatest(actionTypes.EMBED_CREATE_INIT, embedCreateWorker),
    takeLatest(actionTypes.EMBED_UPDATE_INIT, embedUpdateWorker),
  ]);
}
