import axios from "axios";
import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useLocation } from "react-router-dom";
import useImage from "use-image";
import PdfView from "../../components/PdfView.jsx";
import KonvaCanvasdraw from "./KonvaCanvasdraw.js";

interface Props {
  handleImageSizeError: () => void;
  panning: boolean;
  setPanning: any;
  rotate: number;
  handleTypeErrorModal: () => void;
  image: string;
  setTestWidth: Dispatch<SetStateAction<number>>;
  setTestHeight: Dispatch<SetStateAction<number>>;
  isAdd: boolean;
}

type LocationProps = {
  state: {
    imgURL: string;
    file: any;
    isAdd: boolean;
    id: number;
    title: string;
    width: number;
    height: number;
    mode: string;
  };
};

const ScreenContainer = (props: Props) => {
  const screenContainer = useRef<HTMLDivElement | null>(null);
  const location = useLocation() as unknown as LocationProps;
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [imageCheck, setImageCheck] = useState<boolean>(true);
  const [image, status] = useImage(props.image);
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (props.isAdd) {
      setImageUrl(props.image);
      if (props.image?.substring(0, 10) === "data:image") setImageCheck(true);
      else setImageCheck(false);
    } else {
      setImageCheck(true);

      const labcodeImageUrl = axios
        .get(
          `${process.env.REACT_APP_BASIC_URI}/program/product/${location.state.id}/detail`
        )
        .then((data) => {
          setImageUrl(data.data.labcodeImageUrl);
        });
    }
  }, []);

  const img = new Image();
  useEffect(() => {
    if (imageCheck && props.isAdd) {
      img.src = props.image;
      if (img?.width < 512 || img?.height < 512) {
        props.handleImageSizeError();
        // navigate("/normal");
      } else if (img?.width > 3000 || img?.height > 3000) {
        props.handleImageSizeError();
        // navigate("/normal");
      }
    }
  }, [img]);

  return (
    <div className="screen-center-container" ref={screenContainer}>
      {/* <KonvaCanvasdraw
        KonvaWidth={screenWidth}
        KonvaHeight={screenHeight}
        handleImageSizeError={props.handleImageSizeError}
        handleTypeErrorModal={props.handleTypeErrorModal}
        panning={props.panning}
        setPanning={props.setPanning}
        rotate={props.rotate}
      /> */}

      {imageCheck ? (
        <img src={imageUrl} />
      ) : (
        <PdfView
          pdf={props.image}
          setTestWidth={props.setTestWidth}
          setTestHeight={props.setTestHeight}
        />
      )}
    </div>
  );
};
export default ScreenContainer;
