interface Props {
  isProgress: string;
}

const UploadSVG = (props: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill={props.isProgress === "upload" ? "#00B48E" : "#9EA3AA"} />
      <path
        d="M8.06814 7.148V5.24H11.3921V14H9.25614V7.148H8.06814Z"
        fill={props.isProgress === "upload" ? "#F6F7F8" : "#555963"}
      />
    </svg>
  );
};

export default UploadSVG;
