import { combineReducers } from "redux";
import pages, { getPagesSaga } from "./Pages";
import login, { getLoginSaga } from "./Login";
import normal, { getNormalSaga } from "./Normal";
import Embed, { getEmbedSaga } from "./Embed";
import Detect, { getDetectSaga } from "./Detect";
import { all } from "redux-saga/effects";

const rootReducer = combineReducers({
  pages,
  login,
  normal,
  Embed,
  Detect,
});

export function* rootSaga() {
  yield all([getPagesSaga(), getLoginSaga(), getNormalSaga(), getEmbedSaga(), getDetectSaga()]);
}

export type RootReducerType = ReturnType<typeof rootReducer>;

export default rootReducer;
