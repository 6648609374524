import minus from "../../assets/icons/embed/minus.svg";
import plus from "../../assets/icons/embed/plus.svg";
import reset from "../../assets/icons/embed/reset.svg";
import { ReactComponent as Grab } from "../../assets/icons/embed/grab.svg";
import rotate from "../../assets/icons/embed/rotate.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerType } from "../../modules";
import { embedClosePreview, embedScale } from "../../modules/Embed";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { detectReset } from "../../modules/Detect";
import { useEffect, useState } from "react";

interface Props {
  scale: number;
  setScale: any;
  panning: boolean;
  setPanning: any;
  rotate: number;
  setRotate: any;
}

const BottomBar = (props: Props) => {
  const dispatch = useDispatch();
  const EmbedState = useSelector((state: RootReducerType) => state.Embed);
  const navigate = useNavigate();
  const BASICURL = process.env.REACT_APP_BASIC_URI;
  const handleScaleUp = () => {
    if (props.scale < 2.0) props.setScale((prev: number) => prev + 0.1);
  };

  const handleScaleDown = () => {
    if (props.scale >= 0.2) props.setScale((prev: number) => prev - 0.1);
  };

  const handleScaleReset = () => {
    // dispatch(embedScale(1));
    props.setScale(1);
  };

  const [projectId, setProjectId] = useState(0);

  // useEffect(() => {
  //   axios.get(`${BASICURL}/program/projects?bmCode=0`).then((response) => {
  //     setProjectId(response.data.projects.id);
  //   });
  // }, []);

  const embeddingData = {
    // projectId: projectId,
    // productPrintInfoId: 1,
    code: EmbedState.data?.productCode,
    title: EmbedState.embedData?.title,
    // description: "description",
    url: EmbedState.embedData?.url,
    // urlPurchase: "urlPurchase",
    // urlCustom: "urlCustom",
    // urlInstagram: "urlInstagram",
    embedding: "v25",
    channel: EmbedState.embedData?.channel,
    colorSpace: EmbedState.embedData?.colorSpace,
    scale: 4,
    alpha: Number(EmbedState.embedData?.alpha),
    isMass: false,
    unit: 1,
    amount: 1,
    dpi: 300,
    sourceImage: EmbedState.data?.sourceImageUrl,
    resizedSourceImage: EmbedState.data?.resizedSourceImageUrl,
    labcodeImage: EmbedState.data?.labcodeImageUrl,
  };

  const embeddingHandler = () => {
    axios
      .post(`${BASICURL}/program/products`, embeddingData, {
        headers: { Accept: `application/json` },
      })
      .then(() => {
        dispatch(detectReset());
        dispatch(embedClosePreview());
        navigate("/normal");
      })
      .catch((err) => console.log(err));
  };

  const handleRotate = () => {
    if (props.rotate === 270) {
      props.setRotate(0);
    } else {
      props.setRotate(props.rotate + 90);
    }
  };

  const completeEmbeddingHandler = () => {
    dispatch(detectReset());
    dispatch(embedClosePreview());
    navigate("/normal");
  };

  return (
    <div className="bottombar2">
      <div className="bottombar2-left">
        {/* <div className="bottombar2-left-container minus">
          <button onClick={handleScaleDown}>
            <img src={minus} />
          </button>
        </div> */}
        <div className="bottombar2-left-container scale">{`${Math.round(
          (EmbedState.scale - 0.1) * 100
        )}%`}</div>
        {/* <div className="bottombar2-left-container plus">
          <button>
            <img src={plus} onClick={handleScaleUp} />
          </button>
        </div>
        <div className="bottombar2-left-container reset">
          <button>
            <img src={reset} onClick={handleScaleReset} />
          </button>
        </div>
        <div className="bottombar2-left-container grab">
          <button onClick={() => props.setPanning(!props.panning)}>
            <Grab fill={props.panning ? "#00B48E" : "#252A35"} />
          </button>
        </div> */}
        {/* <div className="bottombar2-left-container rotate">
          <button onClick={handleRotate}>
            <img src={rotate} />
          </button>
        </div> */}
      </div>
      <div className="bottombar2-right">
        <button onClick={completeEmbeddingHandler}>등록하기</button>
      </div>
    </div>
  );
};

export default BottomBar;
