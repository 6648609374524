import * as actionTypes from "./actions";
import { AxiosError } from "axios";

type State = {
  scale: number;
  rotate: number;
  width: number;
  height: number;
  error: AxiosError | null;
  embedloading: boolean;
  data: { [key: string]: any } | null;
  getData: { [key: string]: any } | null;
  isPreviewOpen: boolean;
  isCompleteModal: boolean;
  embedData: { [key: string]: any } | null;
  x: number;
  y: number;
};

const initiaState = {
  scale: 1,
  rotate: 0,
  width: 100,
  height: 100,
  error: null,
  embedloading: false,
  data: null,
  getData: null,
  isPreviewOpen: false,
  isCompleteModal: false,
  embedData: null,
  x: 0,
  y: 0,
};

export default function embed(
  state: State = initiaState,
  action: actionTypes.GetEmbedActionsType
): State {
  switch (action.type) {
    case actionTypes.EMBED_SCALE:
      return {
        ...state,
        scale: action.payload,
      };
    case actionTypes.EMBED_SIZE:
      return {
        ...state,
        // width: action.payload,
        // height: action.payload2
      };
    case actionTypes.EMBED_CREATE_INIT:
      return {
        ...state,
        embedloading: true,
        data: action.payload,
        error: null,
        embedData: action.payload2,
      };
    case actionTypes.EMBED_CREATE_SUCCESS:
      return {
        ...state,
        embedloading: false,
        error: null,
        data: action.payload,
        isPreviewOpen: true,
      };
    case actionTypes.EMBED_CREATE_ERROR:
      return {
        ...state,
        embedloading: false,
        error: action.error,
      };
    case actionTypes.EMBED_UPDATE_INIT:
      return {
        ...state,
        embedloading: true,
        data: null,
        embedData: action.payload2,
        error: null,
      };
    case actionTypes.EMBED_UPDATE:
      return {
        ...state,
        embedloading: true,
        data: null,
        error: null,
      };
    case actionTypes.EMBED_UPDATE_SUCCESS:
      return {
        ...state,
        embedloading: false,
        error: null,
        data: action.payload,
      };
    case actionTypes.EMBED_UPDATE_ERROR:
      return {
        ...state,
        embedloading: false,
        error: action.error,
      };
    case actionTypes.EMBED_CLOSE_PREVIEW:
      return {
        ...state,
        isPreviewOpen: false,
      };
    case actionTypes.EMBED_OPEN_PREVIEW:
      return {
        ...state,
        isPreviewOpen: true,
        data: action.payload,
      };
    case actionTypes.EMBED_CLOSE_COMPLETEMODAL:
      return {
        ...state,
        isCompleteModal: false,
      };
    case actionTypes.EMBED_OPEN_COMPLETEMODAL:
      return {
        ...state,
        embedData: action.payload,
        isCompleteModal: true,
        embedloading: false,
      };
    case actionTypes.EMBED_POSITION_XY:
      return {
        ...state,
        x: action.payload,
        y: action.payload2,
      };
    default:
      return state;
  }
}
