import * as actionTypes from "./actions";
import { AxiosError } from "axios";

type State = {
  scale: number;
  width: number;
  height: number;
  detecterror: AxiosError | null;
  detectloading: boolean;
  detectdata: { [key: string]: any } | null;
  getData: { [key: string]: any } | null;
};

const initiaState = {
  scale: 1,
  width: 100,
  height: 100,
  detecterror: null,
  detectloading: false,
  detectdata: null,
  getData: null,
};

export default function detect(
  state: State = initiaState,
  action: actionTypes.GetDetectActionsType
): State {
  switch (action.type) {
    case actionTypes.DETECT_INIT:
      return {
        ...state,
        detectloading: true,
        detectdata: null,
        detecterror: null,
      };
    case actionTypes.DETECT_SUCCESS:
      return {
        ...state,
        detectloading: false,
        detecterror: null,
        detectdata: action.payload,
      };
    case actionTypes.DETECT_ERROR:
      return {
        ...state,
        detectloading: false,
        detecterror: action.error,
      };
    case actionTypes.DETECT_RESET:
      return {
        scale: 1,
        width: 1000,
        height: 1000,
        detecterror: null,
        detectloading: false,
        detectdata: null,
        getData: null,
      };
    default:
      return state;
  }
}
