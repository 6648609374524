import './index.css';
import Router from "./router";
import { BrowserRouter, HashRouter } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
      {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ?
      <BrowserRouter>
        <Router />
      </BrowserRouter> : 
      <HashRouter>
        <Router />
      </HashRouter>
      }
    </>
  );
}

export default App;
