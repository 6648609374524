import { useState } from "react";
import "../../assets/scss/ImageMagnifier.scss";

const ImageMagnifier = ({
  src,
  width,
  height,
  magnifierHeight = 100,
  magnifieWidth = 100,
  zoomLevel = 1.5,
}: {
  src: string;
  width: string;
  height: string;
  magnifierHeight: number;
  magnifieWidth: number;
  zoomLevel: number;
}) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [[cursorX, cursorY], setCursor] = useState([0, 0]);

  const onMouseEnter = (event: any) => {
    const temp = event.currentTarget;
    const { width, height } = temp.getBoundingClientRect();
    setSize([width, height]);
    setShowMagnifier(true);
  };
  const onMouseLeave = () => setShowMagnifier(false);
  const onMouseMove = (event: any) => {
    const temp = event.currentTarget;
    const { top, left } = temp.getBoundingClientRect();
    const x = event.pageX - left;
    const y = event.pageY - top - window.scrollY;
    setXY([x, y]);
    setCursor([event.pageX, event.pageY]);
  };

  return (
    <div>
      <div
        className="background"
        style={{
          height: height,
          width: width,
        }}
      >
        <img
          src={src}
          style={{ height: height, width: width }}
          alt={"img"}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
        />
      </div>
      <div
        className="magnifier"
        style={{
          border: "4px solid #ffffff",
          backgroundColor: "#E6E8EB",
          display: showMagnifier ? "" : "none",
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          top: `${cursorY - magnifierHeight / 2}px`,
          left: `${cursorX - magnifieWidth / 2}px`,
          backgroundImage: `url('${src}')`,
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
};

export default ImageMagnifier;
