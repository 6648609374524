import React, { useState } from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../assets/scss/reset.scss";
import "../assets/scss/Components.scss";
import downloadComp from "../assets/icons/components/downloadComp.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const remote =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("@electron/remote")
    : null;
interface Props {
  downloadInfo: {
    progress: number;
    completed: boolean;
    total: number;
    loaded: number;
    imgCount: number;
    curCount: number;
  };
  title: string;
  setStopDownload: any;
  modalHandler: () => void;
}

const DownloadModal = (props: Props) => {
  const [DownloadName, setDownloadName] = useState("");

  const stopDownload = () => {
    // props.setStopDownload(true);
    props.modalHandler();
  };

  return (
    <div className="modal-shadow" style={{ display: "flex" }}>
      <div className="modal-save">
        <div className="modal-save-grid">
          <div className="modal-save-grid-name">
            <div>{props.title}</div>
            {props.downloadInfo.imgCount > 0 ? (
              <div>
                <div>
                  {props.downloadInfo.imgCount - props.downloadInfo.curCount} /{" "}
                  {props.downloadInfo.imgCount}
                  <img
                    src={downloadComp}
                    style={
                      props.downloadInfo.curCount === 0
                        ? { display: "inline" }
                        : { display: "none" }
                    }
                  />
                </div>
              </div>
            ) : (
              <div>
                <div>
                  {props.downloadInfo.loaded
                    ? (props.downloadInfo.loaded / props.downloadInfo.total) *
                      100
                    : 0}
                  %
                  <img
                    src={downloadComp}
                    style={
                      props.downloadInfo.loaded === props.downloadInfo.total
                        ? { display: "inline" }
                        : { display: "none" }
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="modal-save-grid-progressbar">
            <LinearProgressWithLabel
              sx={{ borderRadius: "4px", width: "320px", height: "5px" }}
              value={
                props.downloadInfo.progress
                  ? props.downloadInfo.progress
                  : (props.downloadInfo.loaded / 100) * 100
              }
            />
          </div>
          <div className="modal-save-grid-description">
            {(props.downloadInfo.total / 1000000).toFixed(1)}MB
          </div>
        </div>

        <div className="modal-save-confirm">
          {props.downloadInfo.completed ? (
            <>
              <button
                style={{
                  backgroundColor: "#737780",
                  borderBottomLeftRadius: "20px",
                }}
                onClick={stopDownload}
              >
                취소
              </button>
              <button onClick={props.modalHandler}>확인</button>
            </>
          ) : (
            <button onClick={props.modalHandler}>확인</button>
          )}
        </div>
      </div>
    </div>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0bcea5",
    },
  },
});

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", alignItems: "center", width: 1, height: 1 }}>
        <Box sx={{ width: 320 }}>
          <LinearProgress color="primary" variant="determinate" {...props} />
        </Box>
        {/* <Box sx={{ right: 10 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box> */}
      </Box>
    </ThemeProvider>
  );
}

export default DownloadModal;
