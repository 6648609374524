import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ErrorModal from "../../components/ErrorModal";
import tooltip from "../../assets/icons/login/tooltip.svg";
import warning from "../../assets/icons/login/warning.svg";
import { ReactComponent as Tooltipbar } from "../../assets/icons/login/tooltipbar.svg";
import logo from "../../images/login/login-logo.svg";

// css
import "../../assets/fonts/font.css";
import "../../assets/scss/reset.scss";
import "../../assets/scss/Register.scss";
import { styled } from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface FormValues {
  // [key: string]: any;
  name: string;
  email: string;
  phone: string;
  password: string;
  password2?: string;
  birth: string;
  gender: string;
  occupationId: number;
  termsOfServiceIds: number[];
}

const Register = () => {
  const passwordReg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/;
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      email: "",
      phone: "010-1234-5678",
      password: "",
      password2: "",
      birth: "2020-09-11",
      gender: "MALE",
      occupationId: 1,
      termsOfServiceIds: [1, 2],
    },
    validationSchema: Yup.object({
      name: Yup.string().min(2).max(255).required("필수 항목입니다."),
      email: Yup.string()
        .max(255)
        .email("이메일 형식이 잘못되었습니다.")
        .required("필수 항목입니다."),

      phone: Yup.string().max(255).required("필수 항목입니다."),
      password: Yup.string()
        .matches(
          passwordReg,
          "8자 이상, 20자 이하의 영문, 숫자, 특수문자를 조합해서 입력해주세요."
        )
        .min(8, "8자 이상으로 입력해주세요.")
        .max(20, "20자 이하로 입력해주세요.")
        .required("필수 항목입니다."),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "비밀번호가 일치하지 않습니다!")
        .required("필수 입력 값입니다!"),
    }),

    onSubmit: (values) => {
      handleRegisterSubmit(values);
    },
  });

  const handleRegisterSubmit = (values: FormValues) => {
    delete values["password2"];
    axios
      .post(
        `${process.env.REACT_APP_BASIC_URI}/program/auth/register`,
        values,
        {
          headers: {
            "Content-Type": `application/json`,
            Accept: "multipart/form-data",
          },
        }
      )
      .then((data) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="register">
      <div className="register-container">
        <div className="register-logo">
          <Link to="/">
            <img src={logo} alt="logoImage" />
          </Link>
        </div>
        <div className="register-content">
          <div className="register-title">회원가입, &#128075;&#127995;</div>
          <div className="register-description">
            <div>LAB CODE 기술을 원하시는 이미지에</div>
            <div>자유롭게 적용할 수 있어요.</div>
          </div>

          {/* form 시작 */}
          <form className="register-form" onSubmit={formik.handleSubmit}>
            <div
              className={
                formik.touched.name && formik.errors.name
                  ? "register-form-password error"
                  : "register-form-password"
              }
            >
              <label htmlFor="name">이름</label>
              <input
                id="name"
                type="string"
                placeholder="이름"
                {...formik.getFieldProps("name")}
                autoComplete="off"
              />
              <div
                className="register-icon"
                style={{
                  display: formik.values.email === "" ? "none" : "block",
                }}
              ></div>
              {formik.touched.name && formik.errors.name && (
                <div>이름을 적어주세요</div>
              )}
            </div>
            <div
              className={
                formik.touched.email && formik.errors.email
                  ? "register-form-email error"
                  : "register-form-email"
              }
            >
              <label htmlFor="email">이메일</label>
              <input
                id="email"
                type="email"
                placeholder="이메일"
                {...formik.getFieldProps("email")}
              />
              <div
                className="register-icon"
                style={{
                  display: formik.values.email === "" ? "none" : "block",
                }}
                // onClick={() =>
                //   formik.setValues({
                //     email: "",
                //     password: formik.values.password,
                //     remember: isRemember,
                //   })
                // }
              >
                {/* <img
              src={require("../../images/login/icon-login-input-close.svg").default}
              alt="icon-login-input-close"
            /> */}
              </div>
              {/* {formik.touched.email && formik.errors.email && !emailCookie.rememeberEmail ? (
            <div className="error-message">{`${formik.errors.email}`}</div>
          ) : null} */}
              {formik.touched.email && formik.errors.email && (
                <div>올바른 이메일 주소를 입력해 주세요</div>
              )}
            </div>
            <div
              className={
                formik.touched.password && formik.errors.password
                  ? "register-form-password error"
                  : "register-form-password"
              }
            >
              <label htmlFor="password">비밀번호</label>
              <input
                id="password"
                type="password"
                placeholder="비밀번호"
                autoComplete="off"
                {...formik.getFieldProps("password")}
              />
              <div
                className="register-icon"
                style={{
                  display: formik.values.password === "" ? "none" : "block",
                }}
                // onClick={() => setVeiwPassword(!veiwPassword)}
              ></div>

              {formik.touched.password && formik.errors.password && (
                <div>비밀번호를 입력해주세요</div>
              )}
            </div>
            <div
              className={
                formik.touched.password2 && formik.errors.password2
                  ? "register-form-password error"
                  : "register-form-password"
              }
            >
              <label htmlFor="name">비밀번호 확인</label>
              <input
                id="password2"
                type="password"
                placeholder="비밀번호 확인"
                autoComplete="off"
                {...formik.getFieldProps("password2")}
              />
              <div
                className="register-icon"
                style={{
                  display: formik.values.password2 === "" ? "none" : "block",
                }}
                // onClick={() => setVeiwPassword(!veiwPassword)}
              ></div>

              {formik.touched.password2 && formik.errors.password2 && (
                <div>비밀번호를 확인해 주세요</div>
              )}
            </div>
            <div className="register-form-gender">
              <button
                type="button"
                className={
                  formik.values.gender === "MALE"
                    ? "register-form-gender-btn active"
                    : "register-form-gender-btn"
                }
                onClick={() =>
                  formik.setValues({ ...formik.values, gender: "MALE" })
                }
              >
                남자
              </button>
              <button
                type="button"
                className={
                  formik.values.gender === "FEMALE"
                    ? "register-form-gender-btn active"
                    : "register-form-gender-btn"
                }
                onClick={() =>
                  formik.setValues({ ...formik.values, gender: "FEMALE" })
                }
              >
                여자
              </button>
            </div>
            {/* <div>
              <button>남자</button>
              <button>여자</button>
            </div> */}
            {/* <div className="login-save">
              <label htmlFor="saveId" id="saveIdLabel" className="checkbox">
                <input
                  id="saveId"
                  name="saveId"
                  type="checkbox"
                  // checked={isRemember}
                  // onChange={emailRememberHandler}
                />
                <span>아이디 저장</span>
              </label>
              <label htmlFor="saveId"style={{ marginBottom: isError ? "32px" : "60px" }  id="saveIdLabel" className="checkbox">
            <input id="saveId" name="saveId" type="checkbox" checked={isRemember} onChange={emailRememberHandler} />
            <span>로그인 상태 유지</span>
          </label>
            </div> */}
            <button
              disabled={!formik.isValid}
              className="register-button"
              type="submit"
            >
              회원가입
            </button>
          </form>
        </div>
      </div>
      {/* <ErrorModal
        title={errorModal.title}
        content={errorModal.content}
        errorModalOpen={errorModal.open}
        handleErrorModal={handleErrorModal}
      /> */}
    </div>
  );
};

export default Register;
