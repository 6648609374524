import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { loginRequest } from "../../modules/Login";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import ErrorModal from "../../components/ErrorModal";
import { RootReducerType } from "../../modules";
import Cookies from "universal-cookie";

// image
import one from "../../images/guideimage/1.jpg";
import two from "../../images/guideimage/2.jpg";
import three from "../../images/guideimage/3.jpg";
import four from "../../images/guideimage/4.jpg";
import five from "../../images/guideimage/5.jpg";
import six from "../../images/guideimage/6.jpg";
import seven from "../../images/guideimage/7.jpg";

import hand from "../../assets/icons/login/hand.png";
import tooltip from "../../assets/icons/login/tooltip.svg";
import warning from "../../assets/icons/login/warning.svg";
import { ReactComponent as Tooltipbar } from "../../assets/icons/login/tooltipbar.svg";

import logo from "../../images/login/login-logo.svg";

// css
import "../../assets/fonts/font.css";
import "../../assets/scss/reset.scss";
import "../../assets/scss/Login.scss";
import { styled } from "@mui/material";
import { useCookies } from "react-cookie";
import ImageMagnifier from "./ImageMagnifier";
import { Link } from "react-router-dom";

const marks = [
  {
    value: 0,
    label: "1",
  },
  {
    value: 1,
    label: "2",
  },
  {
    value: 2,
    label: "3",
  },
  {
    value: 3,
    label: "4",
  },
  {
    value: 4,
    label: "5",
  },
  {
    value: 5,
    label: "6",
  },
  {
    value: 6,
    label: "7",
  },
];

// const valuetext = (value: number) => {
//   switch (value) {
//     case 0:
//       return "인식률이 많이 떨어질 수 있어요";
//     case 1:
//       return "인식률이 조금 낮아요";
//     case 2:
//       return "";
//     case 3:
//       return "";
//     case 4:
//       return "";
//     case 5:
//       return "랩코드가 잘 보여요";
//     case 6:
//       return "랩코드가 매우 잘 보여요";
//     default:
//       return "";
//   }
// };

interface ErrorProps {
  title: string;
  content: any;
  open: boolean;
}
const cookies =
  process.env.REACT_APP_TARGET === "electron"
    ? window.require("@electron/remote").session.defaultSession.cookies
    : new Cookies();

const Login = () => {
  const CustomSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-thumb": {
      color: "#00B48E",
      "&:hover": {
        boxShadow: "000000",
      },
    },
    "& .MuiSlider-rail": {
      width: "440px",
      height: "12px",
      color: "#F0F1F2",
    },
    "& .MuiSlider-track": {
      width: "440px",
      height: "12px",
      color: "#95E3D3",
    },
    "& .MuiSlider-markLabel": {
      fontSize: "14px",

      color: "#555963",
    },
    "& .MuiSlider-mark": {
      display: "none",
      // fontSize: '20px',
      // color: "#95E3D3",
    },
    "& .MuiSlider-valueLabel": {
      background:
        (value === 0 && "#EF284C") ||
        (value === 1 && "rgba(13, 16, 26, 0.8)") ||
        // (value === 2 && "#FF7F00") ||
        // (value === 3 && "#FFFF00") ||
        // (value === 4 && "#0000FF") ||
        (value === 5 && "rgba(13, 16, 26, 0.8)") ||
        (value === 6 && "#EF284C"),
      color:
        (value === 0 && "#F6F7F8") ||
        (value === 1 && "#FFFFFF") ||
        // (value === 2 && "#FFFFFF") ||
        // (value === 3 && "#000000") ||
        // (value === 4 && "#FFFFFF") ||
        (value === 5 && "#FFFFFF") ||
        (value === 6 && "#F6F7F8"),
      display: "none",
    },
  }));
  const [veiwPassword, setVeiwPassword] = useState(true); //TODO 비밀번호 타입 변경 state
  const [value, setValue] = useState<number>(2);
  const handleChange = (
    e: Event | SyntheticEvent,
    newValue: number | number[]
  ) => {
    setValue(newValue as number);
  };

  const [isTooltip, setIsTootip] = useState(false);

  //#region 아이디 저장하기 만들기
  const [emailCookie, setEmailCookie, removeEmailCookie] = useCookies([
    "rememeberEmail",
  ]);
  // const [emailCookie, setEmailCookie] = useState("");
  // useEffect(() => {
  //   try {
  //     cookies.get({ name: "rememeberEmail" }).then(async (cookies: any) => {
  //       await setEmailCookie(cookies[0].value);
  //       await formik.setValues({
  //         email: cookies[0].value,
  //         password: "",
  //         remember: isRemember,
  //       });
  //       setIsRemember(true);
  //     });
  //   } catch (err) {
  //     formik.setValues({
  //       email: "",
  //       password: "",
  //       remember: false,
  //     });
  //   }
  // }, []);

  const initialIsRember = emailCookie.rememeberEmail ? true : false;
  const [isRemember, setIsRemember] = useState(initialIsRember);
  const emailRememberHandler = () => setIsRemember(!isRemember);
  const InitialState = useSelector((state: RootReducerType) => state.login);
  const [isError, setIsError] = useState(false);

  const [errorModal, setErrorModal] = useState<ErrorProps>({
    open: false,
    title: "",
    content: "",
  });

  const handleErrorModal = (title: any) => {
    setErrorModal({
      ...errorModal,
      open: false,
    });
    // if(title.indexOf('403') === -1 ) logOut();
  };

  //#endregion

  const dispatch = useDispatch();
  //TODO useFormik
  const formik = useFormik({
    //TODO 초기값 설정
    initialValues: {
      email: emailCookie.rememeberEmail,
      password: "",
      remember: isRemember,
    },
    //TODO validation 체크
    validationSchema: Yup.object({
      email: Yup.string()
        .max(255)
        .email("올바른 이메일 주소를 입력해주세요.")
        .required("이메일을 입력해주세요"),
      password: Yup.string()
        .min(8, "8자 ~ 16자 이내로 입력해주세요.")
        .max(16, "8자 ~ 16자 이내로 입력해주세요.")
        .required("비밀번호를 입력해주세요")
        .matches(
          /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/,
          "영문, 숫자로만 입력해주세요."
        ),
    }),
    onSubmit: async (values: any) => {
      (document.activeElement as HTMLElement).blur();
      try {
        // cookie에 email 저장
        if (isRemember) {
          // cookies.set({
          //   // url: "http://localhost:3000",
          //   name: "rememeberEmail",
          //   value: formik.values.email,
          // });
          setEmailCookie("rememeberEmail", formik.values.email, {
            maxAge: 60 * 60 * 24,
          });
        } else {
          // cookies.remove("rememeberEmail");
          removeEmailCookie("rememeberEmail");
        }

        //TODO login dispatch
        await dispatch(loginRequest(values));
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (InitialState.error === "LOGIN_FAILURE") {
      setIsError(true);
      // setErrorModal({
      //   open: true,
      //   title: "로그인 실패.",
      //   content: ["이메일 또는 비밀번호를 다시 한번", <br key="login-error" />, "확인해주세요."],
      // });
    }
  }, [InitialState.error]);

  const openNewWindow = (url: string) => {
    const windowFeature = "width=1440, height=1024";
    window.open(url, "_blank", windowFeature);
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-logo">
          <img src={logo} alt="logoImage" />
        </div>
        <div className="login-content">
          <div className="login-title">
            안녕하세요, &#128075;&#127995;
            {/* <img src={hand} alt="handImage" /> */}
          </div>
          <div className="login-description">
            <div>LAB CODE 기술을 원하시는 이미지에</div>
            <div>자유롭게 적용할 수 있어요.</div>
          </div>

          {/* form 시작 */}
          <form className="login-form" onSubmit={formik.handleSubmit}>
            <div
              className={
                formik.touched.email && formik.errors.email
                  ? "login-form-email error"
                  : "login-form-email"
              }
            >
              <input
                id="email"
                type="email"
                placeholder="이메일"
                {...formik.getFieldProps("email")}
              />
              <div
                className="login-icon"
                style={{
                  display: formik.values.email === "" ? "none" : "block",
                }}
                onClick={() =>
                  formik.setValues({
                    email: "",
                    password: formik.values.password,
                    remember: isRemember,
                  })
                }
              >
                {/* <img
                  src={require("../../images/login/icon-login-input-close.svg").default}
                  alt="icon-login-input-close"
                /> */}
              </div>
              {/* {formik.touched.email && formik.errors.email && !emailCookie.rememeberEmail ? (
                <div className="error-message">{`${formik.errors.email}`}</div>
              ) : null} */}
              {formik.touched.email && formik.errors.email && (
                <div>올바른 이메일 주소를 입력해 주세요</div>
              )}
            </div>
            <div
              className={
                formik.touched.password && formik.errors.password
                  ? "login-form-password error"
                  : "login-form-password"
              }
            >
              <input
                id="password"
                type={veiwPassword ? "password" : "text"}
                placeholder="비밀번호"
                autoComplete="off"
                {...formik.getFieldProps("password")}
              />
              <div
                className="login-icon"
                style={{
                  display: formik.values.password === "" ? "none" : "block",
                }}
                onClick={() => setVeiwPassword(!veiwPassword)}
              >
                <img
                  src={
                    veiwPassword
                      ? require("../../images/login/icon-login-input-not-view.svg")
                          .default
                      : require("../../images/login/icon-login-input-view.svg")
                          .default
                  }
                  alt="icon-login-input-close"
                />
              </div>

              {formik.touched.password && formik.errors.password && (
                <div>비밀번호를 입력해주세요</div>
              )}
            </div>
            <div className="login-save">
              <label htmlFor="saveId" id="saveIdLabel" className="checkbox">
                <input
                  id="saveId"
                  name="saveId"
                  type="checkbox"
                  checked={isRemember}
                  onChange={emailRememberHandler}
                />
                <span>아이디 저장</span>
              </label>
              {/* <label htmlFor="saveId"style={{ marginBottom: isError ? "32px" : "60px" }  id="saveIdLabel" className="checkbox">
                <input id="saveId" name="saveId" type="checkbox" checked={isRemember} onChange={emailRememberHandler} />
                <span>로그인 상태 유지</span>
              </label> */}
            </div>
            <button className="login-button" type="submit">
              로그인
            </button>
            <div className="login-register">
              <div>
                {/* <div
                  onClick={() =>
                    openNewWindow(
                      "https://intergrated-sign-up.netlify.app/find-id?location=program"
                    )
                  }
                >
                  아이디 찾기
                </div>
                <div
                  onClick={() =>
                    openNewWindow(
                      "https://intergrated-sign-up.netlify.app/signup-select?location=program"
                    )
                  }
                >
                  비밀번호 재설정
                </div> */}
              </div>
              <Link to={"/register"}>회원가입</Link>
            </div>
          </form>
          {isError && (
            <div className="login-error">
              <img src={warning} />
              아이디 또는 비밀번호를 다시 확인해 주세요
            </div>
          )}
          <div className="login-guide">
            랩코드를 체험해 보세요!
            <div className="login-guide-tooltip">
              <img src={tooltip} alt="tooltipImage" />
              <div className="tooltipbar">
                <Tooltipbar />
              </div>
            </div>
            {/* {isTooltip && (
              <div className="tooltipbar" onClick={() => setIsTootip(!isTooltip)}>
                <Tooltipbar />
              </div>
            )} */}
          </div>
          {value === 0 && (
            <ImageMagnifier
              src={one}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          {value === 1 && (
            <ImageMagnifier
              src={two}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          {value === 2 && (
            <ImageMagnifier
              src={three}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          {value === 3 && (
            <ImageMagnifier
              src={four}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          {value === 4 && (
            <ImageMagnifier
              src={five}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          {value === 5 && (
            <ImageMagnifier
              src={six}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          {value === 6 && (
            <ImageMagnifier
              src={seven}
              width="440px"
              height="201px"
              magnifieWidth={100}
              magnifierHeight={100}
              zoomLevel={3}
            />
          )}
          <Box sx={{ width: 440, marginTop: "60px", justifyContent: "center" }}>
            <CustomSlider
              aria-label="Custom marks"
              onChangeCommitted={handleChange}
              // getAriaValueText={valuetext}
              defaultValue={value}
              valueLabelDisplay="auto"
              // valueLabelFormat={valuetext}
              marks={marks}
              min={0}
              max={6}
            />
          </Box>
        </div>
      </div>
      <ErrorModal
        title={errorModal.title}
        content={errorModal.content}
        errorModalOpen={errorModal.open}
        handleErrorModal={handleErrorModal}
      />
    </div>
  );
};

export default Login;
