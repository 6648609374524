import { put, all, call, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import * as actionTypes from "./actions";
import { getToken, getTokenCreate } from "../../utils/Cookies/Cookies";

const REACT_APP_BASIC_URI: string =
  `${process.env.REACT_APP_BASIC_URI}/program/scan` as string;

//TODO page 리스트 가져오기(PDF가 서버에 있을 경우)
//TODO PDF 삽입 (multipart/form-data)

//TODO PDF 삽입 (multipart/form-data)
function* detectWorker(payload: any) {
  const config = getTokenCreate();
  try {
    const DETECT: AxiosResponse = yield call(() =>
      axios.post(`${REACT_APP_BASIC_URI}/detect`, payload.payload, {
        headers: {
          "Content-type": `multipart/form-data`,
          Accept: "multipart/form-data",
        },
      })
    );
    yield put(actionTypes.detectSuccess(DETECT.data));
    // window.open(EMBED.data.labcodeImage, EMBED.data.title)
    // childWindow?.document.write('<img src={EMBED.data.labcodeImage}>')

    return DETECT;
  } catch (err: any) {
    console.log(err);
    yield put(actionTypes.detectError(err));
  }
}

export function* getDetectSaga() {
  yield all([takeLatest(actionTypes.DETECT_INIT, detectWorker)]);
}
