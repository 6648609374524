import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerType } from "../../modules";
import { embedClosePreview } from "../../modules/Embed";
import Axios from "axios";
import DownloadModal from "../../components/DownloadModal";

interface Props {
  title: string;
  imgURL: string;
  setStopDownload: any;
}
const ScreenHeader = (props: Props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const EmbedState = useSelector((state: RootReducerType) => state.Embed);
  const handleRetry = () => {
    dispatch(embedClosePreview());
  };

  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    imgCount: 0,
    curCount: 0,
  });

  const mode = process.env.REACT_APP_MODE === "app";

  const modalHandler = () => {
    setModal(false);
    setDownloadInfo({
      progress: 0,
      loaded: 0,
      total: 0,
      completed: false,
      imgCount: 0,
      curCount: 0,
    });
  };

  return (
    <div className="screen">
      <div className="preview-header">
        <div className="preview-header-left"></div>
        <div className="preview-header-center">{props.title}</div>
        <div className="preview-header-right">
          <button className="preview-header-right-button" onClick={handleRetry}>
            닫기
          </button>
        </div>
      </div>
      {modal && (
        <DownloadModal
          title={props.title}
          downloadInfo={downloadInfo}
          modalHandler={modalHandler}
          setStopDownload={props.setStopDownload}
        />
      )}
    </div>
  );
};
export default ScreenHeader;
