import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import rootReducer, { rootSaga } from "./modules";
import createSagaMiddleware from "redux-saga";
import { CookiesProvider } from "react-cookie";
import { createLogger } from "redux-logger";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({});
const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware, logger],
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

sagaMiddleware.run(rootSaga);

root.render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
