import { useEffect, useRef, useState } from "react";
import "../../assets/scss/Listmode.scss";
import Axios from "axios";
import DownloadModal from "../../components/DownloadModal";
import { ReactComponent as Link } from "../../assets/icons/normal/link.svg";
import { ReactComponent as LinkCopy } from "../../assets/icons/normal/linkcopy.svg";
import ToastMessage from "../../components/ToastMessage";
import useImage from "use-image";

const ListMode = (props: any) => {
  const mode = process.env.REACT_APP_MODE === "app";

  const [onDrag, setOnDrag] = useState(false);
  const [dragStart, setDragStart] = useState(-1);
  const [dragEnd, setDragEnd] = useState(-1);

  const [modal, setModal] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    imgCount: 0,
    curCount: 0,
  });
  const [imgTitle, setImgTitle] = useState("");

  const alphaValue = (alpha: number): number => {
    const value: any = {
      40: 1,
      50: 2,
      60: 3,
      70: 4,
      80: 5,
    };
    return value[alpha] ?? 0;
  };

  const dateStringCutter = (value: string) => {
    var hours = Number(value.slice(11, 13));
    var ampm = hours >= 12 ? "오후" : "오전";
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${value.slice(0, 4)}.${value.slice(5, 7)}.${value.slice(8, 10)}`;
  };

  const modalHandler = () => {
    setModal(false);
    setDownloadInfo({
      progress: 0,
      loaded: 0,
      total: 0,
      completed: false,
      imgCount: 0,
      curCount: 0,
    });
  };

  const handleListMouseDown = (e: any, index: number) => {
    setOnDrag(true);
    setDragStart(index);
  };
  const handleListMouseUp = () => {
    setOnDrag(false);
  };

  const handleListMouseEnter = (e: MouseEvent, index: number) => {
    setDragEnd(index);
    var totalHeight = window.innerHeight;
    if (totalHeight - e.clientY < 85) {
      setTimeout(() => {
        if (onDrag) {
          const scrollTemp = document.getElementById("list-scroller");
          if (scrollTemp) {
            scrollTemp.scrollTo({ top: scrollTemp.scrollTop + 52 });
          }
        }
      }, 500);
    }
  };

  // const [toastState, setToastState] = useState(false);

  const copyToClipboard = (url: string) => {
    const tmp = document.createElement("input");
    document.body.appendChild(tmp);
    tmp.value = url;
    tmp.select();
    document.execCommand("copy");
    document.body.removeChild(tmp);
    props.setToastState(true);
  };

  const openNewWindow = (url: string) => {
    const windowFeature = "width=1440, height=1024";
    window.open(url, "_blank", windowFeature);
  };

  // useEffect(() => {
  //   if (toastState)
  //     setTimeout(() => {
  //       setToastState(false);
  //     }, 3000);
  // }, [toastState]);

  useEffect(() => {
    if (dragStart !== dragEnd && dragStart !== -1 && dragEnd !== -1 && onDrag) {
      props.handleDragCheckImage(dragStart, dragEnd);
    } else if (dragStart === dragEnd && dragStart !== -1 && onDrag) {
      props.handleCheckImage(dragStart);
    }
  }, [dragStart, dragEnd, onDrag]);

  useEffect(() => {
    window.addEventListener("mouseup", handleListMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleListMouseUp);
    };
  }, []);

  const imgSize = (sourceImage: string) => {
    let image = new Image();
    image.src = sourceImage;
    return [image.naturalWidth, image.naturalHeight];
  };

  return (
    <div className="wrapper">
      <div className="column">
        <div className="column-num">
          <span>번호</span>
        </div>
        <div className="column-img">
          <span>이미지</span>
        </div>
        <div className="column-title">
          <span>제목</span>
        </div>
        <div className="column-date">
          <span>생성일</span>
        </div>
        <div className="column-type">
          <span>종류</span>
        </div>
        <div className="column-size">
          <span>규격</span>
        </div>
        <div className="column-alpha">
          <span>기술 세기</span>
        </div>
        <div className="column-link">
          <span>링크 열기</span>
        </div>
        <div className="column-copy">
          <span>링크 복사</span>
        </div>
      </div>

      {modal && (
        <DownloadModal
          title={imgTitle}
          downloadInfo={downloadInfo}
          modalHandler={modalHandler}
          setStopDownload={props.setStopDownload}
        />
      )}

      <div className="listdata" id="list-scroller">
        {props.data?.map((item: any, index: number) => (
          <div
            className={
              props.isChecked[index]
                ? "listdata-wrapper selected"
                : "listdata-wrapper"
            }
            key={item.id}
            // onClick={() => handleCheckImage(index)}
            onMouseEnter={(e: any) => handleListMouseEnter(e, index)}
            // onMouseUp={(e: any) => handleListMouseUp(e, index)}
            onMouseDown={(e: any) => handleListMouseDown(e, index)}
          >
            <div className="listdata-num">
              <span>{index + 1}</span>
            </div>
            <div className="listdata-img">
              <img
                src={item?.resizedSourceImage || item?.sourceImageUrl}
                alt={`card-image_${item?.id}`}
              />
              <div
                className="listdata-img-hover"
                onClick={() =>
                  props.handleClickNormal(
                    item.id,
                    item.sourceImageUrl,
                    false,
                    item.width,
                    item.height,
                    item.title,
                    item.channel,
                    item.colorSpace,
                    item.url,
                    item.alpha,
                    "list"
                  )
                }
              >
                수정
              </div>
            </div>
            <div className="listdata-title">
              <span>{item.title}</span>
            </div>
            <div className="listdata-date">
              <span>{dateStringCutter(item.created)}</span>
            </div>
            <div className="listdata-type">
              <span>{item?.fileFormat} 이미지</span>
            </div>
            <div className="listdata-size">
              <span>
                {imgSize(item.sourceImageUrl)[0]} x
                {imgSize(item.sourceImageUrl)[1]}
              </span>
            </div>
            <div className="listdata-alpha">
              <span>
                {alphaValue(item.alpha) ? alphaValue(item.alpha) : "-"}
              </span>
            </div>
            <div className="listdata-link">
              <button onClick={() => openNewWindow(item.url)}>
                <Link />
              </button>
            </div>
            <div className="listdata-copy">
              <button onClick={() => copyToClipboard(item.url)}>
                <LinkCopy />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListMode;
