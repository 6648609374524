import * as actionTypes from "./actions";

type State = {
  data: { [key: string]: any } | null;
  error: string | null;
  isLoggingIn: boolean;
  token: { [key: string]: any } | null;
};

const initiaState = {
  data: null,
  error: null,
  isLoggingIn: false,
  token: null,
};

export default function sections(
  state: State = initiaState,
  action: actionTypes.GetBooksActionsType
): State {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: false,
        data: action.payload,
        error: null,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: true,
        error: null,
        token: action.payload,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        error: null,
        token: null,
        data: null,
      };
    case actionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
