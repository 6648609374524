import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import completeIcon from "../assets/icons/embed/completeIcon.svg";
import { detectReset } from "../modules/Detect";
import { embedCloseCompleteModal } from "../modules/Embed";

interface Props {
  add: boolean;
  mode: string;
}

const CompleteModal = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const completeHandler = () => {
    dispatch(detectReset());
    dispatch(embedCloseCompleteModal());
    navigate("/normal", {
      state: {
        mode: props.mode,
      },
    });
  };

  return (
    <div className="complete-backdrop">
      <div className="completeWrapper">
        <div className="completeWrapper-content">
          <img src={completeIcon} />
          <div className="completeWrapper-content-title">
            {props.add ? "등록 완료" : "수정 완료"}
          </div>
          <div className="completeWrapper-content-body">
            랩코드기술이 적용되어 {props.add ? "등록" : "수정"}이
            <br /> 완료되었습니다
          </div>
        </div>
        <div className="completeWrapper-btn" onClick={completeHandler}>
          확인
        </div>
      </div>
    </div>
  );
};

export default CompleteModal;
