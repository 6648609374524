interface Props {
  trueCount: number;
  checkImageCount: number;
  dataCount: number;
}

const BottomBar = (props: Props) => {
  return (
    <div className="main-bottombar">
      {props.trueCount !== 0 ? (
        <div>
          {props.trueCount}/{props.checkImageCount} 선택됨
        </div>
      ) : (
        <div>{props.dataCount}개의 항목</div>
      )}
    </div>
  );
};

export default BottomBar;
