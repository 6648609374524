import { useRef, useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { RootReducerType } from "../../modules";
import {
  productPageInit,
  productCreateInit,
  productUpdateInit,
  productDeleteInit,
} from "../../modules/Normal";
import { logoutRequest } from "../../modules/Login";
import DeleteModal from "../../components/DeleteModal";
import ErrorModal from "../../components/ErrorModal";
import Loading from "../../components/Loading";
import "../../assets/fonts/font.css";
import "../../assets/scss/reset.scss";
import "../../assets/scss/Normal.scss";
import "../../assets/scss/Pages.scss";
import { detectInit, detectReset } from "../../modules/Detect";
import FileSaver from "file-saver";
import JSZip from "jszip";
import Axios from "axios";
import DownloadModal from "../../components/DownloadModal";
import ViewMode from "./ViewMode";
import ListMode from "./ListMode";
import { ReactComponent as TopBtn } from "../../assets/icons/normal/topbtn.svg";
import GalleryMode from "./GalleryMode";
import DetectMode from "./DetectMode";
import ToastMessage from "../../components/ToastMessage";
import BottomBar from "./BottomBar";
import ContainerMenuBar from "./ContainerMenuBar";
import { channel } from "diagnostics_channel";
import EmailForm from "../../components/EmailForm";

let Title = "";
interface ErrorProps {
  title: string;
  content: any;
  open: boolean;
}

interface DetectProps {
  item: any;
  file: any;
  fileName: any;
}

const Normal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const CreateFileRef = useRef<HTMLInputElement>(null);
  const DetectFileRef = useRef<HTMLInputElement | null>(null);
  const UpdataFileRef = useRef<HTMLInputElement | null>(null);
  const UpdateDetectFileRef = useRef<HTMLInputElement | null>(null);
  const NormalState = useSelector((state: RootReducerType) => state.normal);
  const DetectState = useSelector((state: RootReducerType) => state.Detect);

  const { loading, data, error } = NormalState;
  const { detectloading, detectdata, detecterror } = DetectState;

  const [scrollLoading, setScrollLoading] = useState(false);
  const [workNormalListId, setWorkNormalListId] = useState<string | null>();
  const [DeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<ErrorProps>({
    open: false,
    title: "",
    content: "",
  });
  const [checkImage, setCheckImage] = useState<any>([]);
  const [tempCheckImage, setTempCheckImage] = useState<any>([]);
  const [isFirstShift, setIsFirstShift] = useState<boolean>(true);
  // const [viewCheckImage, setViewCheckImage] = useState<any>([]);
  // const [listCheckImage, setListCheckImage] = useState<any>([]);

  const [addImage, setAddImage] = useState<any>([]);
  const [isEmbed, setIsEmbed] = useState<boolean>(true);
  const [normalMode, setNormalMode] = useState("embed");

  const [isDetected, setIsDetected] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [detectItem, setDetectItem] = useState<DetectProps>({
    item: null,
    file: null,
    fileName: null,
  });
  const [log, setLog] = useState<any>([]);
  const [viewMode, setViewMode] = useState(
    location.state?.mode ? location.state.mode : "gallery"
  );
  const [embeddingMode, setEmbeddingMode] = useState<string>("");

  const [modal, setModal] = useState(false);
  const [askModal, setAskModal] = useState<boolean>(false);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    imgCount: 0,
    curCount: 0,
  });
  const [stopDownload, setStopDownload] = useState<boolean>(false);
  const [showButton, setShowButton] = useState(true);
  const scrollTemp = document.getElementById("list-scroller");

  const scrollToTop = () => {
    scrollTemp?.scrollTo(0, 0);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const mode = process.env.REACT_APP_MODE === "app";

  // console.log(data);

  useEffect(() => {
    //TODO book list 조회
    dispatch(productPageInit());
  }, [dispatch]);

  useEffect(() => {
    if (detecterror && isEmbed) {
      if (detecterror.response?.status === 403) {
        console.log(addImage);
        navigate(`/normal/screen/${addImage?.id}`, {
          state: {
            imgURL: addImage?.sourceImage,
            file: addImage?.file,
            isAdd: true,
            title: addImage?.title,
            id: addImage?.id,
            embeddingMode: addImage?.embeddingMode,
          },
        });
      } else if (detecterror.response?.status === 400) {
        setErrorModal({
          open: true,
          title: "프로그램용 이미지가 아닙니다.",
          content:
            "일시적인 현상이거나 네트워크 문제일 수 있으니\r\n 잠시 후 다시 시도해주세요.",
        });
        setAddImage(null);
        // setIsAdded(false);
      }
    }
  }, [detecterror]);

  useEffect(() => {
    if (detectdata && isAdded && isEmbed) {
      setErrorModal({
        open: true,
        title: "랩코드 기술이 적용된 이미지 입니다.",
        content:
          "일시적인 현상이거나 네트워크 문제일 수 있으니\r\n 잠시 후 다시 시도해주세요.",
      });
      setIsAdded(false);
      setAddImage(null);
    }
  }, [detectdata]);

  useEffect(() => {
    if (location.state?.sizeError) {
      console.log(location.state);
      setErrorModal(location.state?.sizeErrorState);
      location.state = null;
    }
  }, [location.state]);

  useEffect(() => {
    let arr = Array.from({ length: data?.products.length }, () => false);
    setCheckImage(arr);
    setTempCheckImage(arr);
    setLastIndex(-1);
    setTrueCount(0);
  }, [data?.data, viewMode]);

  useEffect(() => {
    let tmpCount = 0;
    checkImage.forEach((image: any) => {
      if (image) tmpCount += 1;
    });
    setTrueCount(tmpCount);

    if (trueCount > 0) setIsDownload(true);
    else setIsDownload(false);
  }, [checkImage]);

  const [toastState, setToastState] = useState(false);

  useEffect(() => {
    if (toastState)
      setTimeout(() => {
        setToastState(false);
      }, 3000);
  }, [toastState]);

  const handleClickCreateFile = () => {
    if (!CreateFileRef.current) return;
    CreateFileRef.current.click();
  };

  //TODO BOOK 생성

  const CreateNormal = async (event: any) => {
    if (!event.target.files[0]) return;
    const file = event.target.files[0];
    console.log(file);
    Title = file.name.split(".");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setAddImage({
        title: Title[0],
        sourceImage: reader.result,
        file: file,
        id: 50001,
        embeddingMode: embeddingMode,
      });
      console.log(reader, "reader");
      setScrollLoading(true);
      const formData = new FormData();
      formData.append("embeddedImage", file);
      dispatch(detectInit(formData));
      setIsAdded(true);

      // navigate(`/normal/screen/${id}`, {state:{imgURL, file, isAdd, title}});
      // dispatch(productCreateInit(newNormalItem));
      // setTimeout(() => {
      //   setScrollLoading(false);
      //   navigate(`/normal/screen/${50001}`, {
      //     state: { imgURL: reader.result, file: file, isAdd: true, title: Title[0], id: 50001 },
      //   });
      // }, 1000);
    };
  };

  //TODO BOOK 이미지 변경
  const handleClickUpdateFile = useCallback((e: any, id: any) => {
    e.stopPropagation();
    setWorkNormalListId(id);
    if (!UpdataFileRef.current) return;
    UpdataFileRef.current.click();
  }, []);

  const UpdateNormalImage = (event: any) => {
    if (!event.target.files[0]) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const updateNormal = {
        id: workNormalListId,
        image_cover: reader.result,
      };
      dispatch(productUpdateInit(updateNormal));
    };
  };

  //TODO BOOK DELETE
  const normalDeleteModal = (e: any, id: any) => {
    handleDeleteModal();
    setWorkNormalListId(id);
  };

  const normalDelete = (id: any) => {
    handleDeleteModal();
    const deleteId = {
      id: workNormalListId,
    };
    dispatch(productDeleteInit(deleteId));
  };

  const handleClickNormal = (
    id: any,
    imgURL: string,
    isAdd: boolean,
    width: any,
    height: any,
    title: string,
    channel: string,
    colorSpace: string,
    link: string,
    alpha: number,
    mode: string
  ) => {
    // history.push(`/books/pages/${id}`);
    navigate(`/normal/screen/${id}`, {
      state: {
        imgURL,
        isAdd,
        width,
        height,
        id,
        title,
        channel,
        colorSpace,
        link,
        alpha,
        mode,
      },
    });
  };

  const handleDeleteModal = () => {
    setDeleteModalOpen(!DeleteModalOpen);
  };
  const handleErrorModal = (title: any) => {
    setErrorModal({
      ...errorModal,
      open: false,
    });
    // if(title.indexOf('403') === -1 ) logOut();
    setScrollLoading(false);
  };
  const logOut = () => {
    dispatch(logoutRequest());
    dispatch(detectReset());
  };

  const [ctrl, setCtrl] = useState(false);
  const [shift, setShift] = useState(false);
  const [lastIndex, setLastIndex] = useState<number>(-1);
  const [prevIndex, setPrevIndex] = useState<number>(-1);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    window.addEventListener("keyup", handleUserKeyUp);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
      window.removeEventListener("keyup", handleUserKeyUp);
    };
  });

  const handleUserKeyPress = (event: any) => {
    event.stopPropagation();
    const { key, keyCode } = event;
    if (!event) return;
    if (keyCode === 17 || keyCode === 91) setCtrl(true);
    else if (keyCode === 16) setShift(true);
  };

  const handleUserKeyUp = (event: any) => {
    event.stopPropagation();
    const { key, keyCode } = event;
    if (!event) return;
    if (keyCode === 17 || keyCode === 91) setCtrl(false);
    else if (keyCode === 16) setShift(false);
  };

  const [trueCount, setTrueCount] = useState<number>(0);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [curTitle, setCurTitle] = useState<string>("");

  const returnRange = (start: number, end: number, i: number) => {
    if (start < end) return start <= i && end >= i;
    else return start >= i && end <= i;
  };

  const handleCheckImage = (i: number) => {
    let arr = Array.from({ length: data?.products.length }, () => false);
    setCheckImage(
      arr.map((item: boolean, index: number) => (index === i ? !item : item))
    );
    setLastIndex(i);
    setIsFirstShift(true);

    // if (ctrl || (shift && i === lastIndex)) {
    //   setCheckImage(
    //     checkImage.map((item: boolean, index: number) =>
    //       index === i ? !item : item
    //     )
    //   );
    //   setLastIndex(i);
    //   setIsFirstShift(true);
    // } else if (shift) {
    //   if (isFirstShift) {
    //     setTempCheckImage(checkImage);
    //     setCheckImage(
    //       checkImage.map((item: boolean, index: number) =>
    //         returnRange(lastIndex, i, index) ? true : item
    //       )
    //     );
    //     setIsFirstShift(false);
    //   } else {
    //     setCheckImage(
    //       tempCheckImage.map((item: boolean, index: number) =>
    //         returnRange(lastIndex, i, index) ? true : item
    //       )
    //     );
    //   }
    // } else {
    //   setCheckImage(
    //     arr.map((item: boolean, index: number) => (index === i ? !item : item))
    //   );
    //   setLastIndex(i);
    //   setIsFirstShift(true);
    // }
  };

  // const handleDragCheckImage = (start: number, end: number) => {
  //   let arr = Array.from({ length: data?.products.length }, () => false);
  //   setLastIndex(start);
  //   setIsFirstShift(false);
  //   if (ctrl) {
  //     setCheckImage(
  //       checkImage.map((item: boolean, index: number) =>
  //         returnRange(start, end, index) ? true : item
  //       )
  //     );
  //   } else {
  //     setCheckImage(
  //       arr.map((item: boolean, index: number) =>
  //         returnRange(start, end, index) ? true : item
  //       )
  //     );
  //   }
  // };
  const handleMultiDownload = (checkImage: any) => {
    const zip = new JSZip();
    const count = 0;
    const zipFileName = "test.zip";
    let urls: any = [];
    let title: any = [];
    setModal(true);

    checkImage.forEach(async (item: boolean, index: any) => {
      if (item === true && data?.products) {
        urls.push(data.products[index].labCodeImageUrl);
        title.push(data.products[index].title);
      }
    });
    if (urls.length === 0) {
      setModal(false);
      return;
    } else if (urls.length === 1) {
      const tmp = urls[0].substring(62);

      const options = {
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          setDownloadInfo({
            progress: Math.floor((loaded * 100) / total),
            loaded,
            total,
            completed: false,
            imgCount: 0,
            curCount: 0,
          });
        },
      };

      let downloadUrl = `${process.env.REACT_APP_DOWNLOAD_URL}/image` + tmp;
      setCurTitle(title[0]);

      console.log(tmp);
      console.log(downloadUrl);
      Axios.get(downloadUrl, {
        responseType: "blob",
        ...options,
      })
        .then(function (response) {
          return response;
        })
        .then((response) => {
          const link = document.createElement("a");
          link.setAttribute("download", `${title[0]}_embeded.jpg`);
          document.body.appendChild(link);
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          link.href = url;
          link.click();
          link.remove();
        });
    } else {
      (async function () {
        let imgCount = urls.length;
        let curCount = urls.length;
        let firstTitle = title[0];
        const titleCount = title.reduce(
          (ac: any, v: string) => ({ ...ac, [v]: (ac[v] || 0) + 1 }),
          {}
        );
        for (let i in titleCount) {
          if (titleCount[i] === 1) titleCount[i] = 0;
        }
        while (curCount > 0 && !stopDownload) {
          curCount -= 1;
          const popData = urls.pop();
          const titleData = title.pop();
          const tmp = popData.substring(62);
          let downloadUrl =
            `${process.env.REACT_APP_DOWNLOAD_URL}/image/V2/` + tmp;
          const response: any = await fetch(downloadUrl);
          const blob: any = await response.blob();

          if (titleCount[titleData] === 0) {
            zip.file(`${titleData}_embeded.jpg`, blob);
          } else {
            zip.file(
              `${titleData}_embeded(${titleCount[titleData]}).jpg`,
              blob
            );
            titleCount[titleData] -= 1;
          }
          // if (title.includes(titleData)) {
          // } else {
          //   zip.file(`${titleData}_embeded.jpg`, blob);
          // }
          // zip.file(`${titleData}(${curCount + 1})_embeded.jpg`, blob);
          setCurTitle(titleData);
          setDownloadInfo({
            progress: Math.floor(((imgCount - curCount) / imgCount) * 100),
            loaded: Math.floor(((imgCount - curCount) / imgCount) * 100),
            total: blob.size,
            completed: true,
            curCount: curCount,
            imgCount: imgCount,
          });
        }
        setStopDownload(false);
        zip.generateAsync({ type: "blob" }).then((content) => {
          FileSaver.saveAs(
            content,
            `${firstTitle} 외 ${imgCount - curCount - 1}개`
          );
        });
      })();
    }
  };

  const handlePrintOut = (checkImage: any) => {
    let urls: any = [];
    let title: any = [];
    const iframe = document.createElement("iframe");
    checkImage.forEach(async (item: boolean, index: any) => {
      if (item === true && data?.products) {
        urls.push(data.products[index].labCodeImageUrl);
        title.push(data.products[index].title);
      }
    });

    if (urls.length === 1) {
      const tmp = urls[0].substring(62);
      setCurTitle(title[0]);
      let downloadUrl = `${process.env.REACT_APP_DOWNLOAD_URL}/image` + tmp;
      Axios.get(
        // urls[0],
        mode ? urls[0] : downloadUrl,
        {
          responseType: "blob",
        }
      )
        // .then(function (response) {
        //   return response;
        // })
        // .then((response) => {})
        .then(() => {
          iframe.style.height = "0";
          iframe.style.visibility = "hidden";
          iframe.style.width = "0";
          iframe.setAttribute(
            "srcdoc",
            `<html style="width:100%; height:100%; margin:auto; padding:auto;page-break-after:always"><body style="width:100%; margin:0; padding:0 display:flex; justify-content:center; align-items:center">
            <img style="width:100%;object-fit:contain;margin:auto" src="${downloadUrl}"
            </body></html>`
            // `<html ><body>
            // <img src="${downloadUrl}"
            // </body></html>`
          );
          document.body.appendChild(iframe);
          iframe.contentWindow?.print();
          // iframe.contentWindow?.onafterprint()
          if (iframe.contentWindow) {
            iframe.contentWindow.onafterprint = () => {
              document.body.removeChild(iframe);
            };
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorModal({
            open: true,
            title: "프린트 호출 에러",
            content:
              "일시적인 현상이거나 네트워크 문제일 수 있으니\r\n 잠시 후 다시 시도해주세요.",
          });
        });
    } else return;
  };

  const modalHandler = () => {
    setModal(false);
    setDownloadInfo({
      progress: 0,
      loaded: 0,
      total: 0,
      completed: false,
      imgCount: 0,
      curCount: 0,
    });
  };

  const modeSelection = (viewMode: string) => {
    if (viewMode === "gallery") {
      return (
        <>
          <GalleryMode
            CreateNormal={CreateNormal}
            data={data?.products}
            checkImage={checkImage}
            setCheckImage={setCheckImage}
            handleClickUpdateFile={handleClickUpdateFile}
            handleCheckImage={handleCheckImage}
            normalDeleteModal={normalDeleteModal}
            handleClickNormal={handleClickNormal}
            UpdateNormalImage={UpdateNormalImage}
            setToastState={setToastState}
            setStopDownload={setStopDownload}
            dataCount={data?.total}
            setErrorModal={setErrorModal}
            setEmbeddingMode={setEmbeddingMode}
          />
          {scrollLoading && <Loading detect={false} />}
        </>
      );
    } else if (viewMode === "thumbnail")
      return (
        <ViewMode
          data={data?.products}
          handleCheckImage={handleCheckImage}
          // handleDragCheckImage={handleDragCheckImage}
          isChecked={checkImage}
          setToastState={setToastState}
          setStopDownload={setStopDownload}
          handleClickNormal={handleClickNormal}
        />
      );
    else
      return (
        <ListMode
          data={data?.products}
          handleCheckImage={handleCheckImage}
          // handleDragCheckImage={handleDragCheckImage}
          isChecked={checkImage}
          setToastState={setToastState}
          setStopDownload={setStopDownload}
          handleClickNormal={handleClickNormal}
        />
      );
  };

  const refreshData = () => {
    dispatch(productPageInit());
    let arr = Array.from({ length: checkImage.length }, () => false);
    setCheckImage(arr);
  };

  return (
    <div className="main">
      <div className="main-header">
        <div className="logo" onClick={refreshData}>
          <img
            src={require("../../images/pages/logo.svg").default}
            alt="logo"
          />
        </div>

        <div className="modeSelect">
          <div
            className={isEmbed ? "modeSelect-text active" : "modeSelect-text"}
            onClick={() => setIsEmbed(true)}
          >
            기술 적용 모드
          </div>
          <div
            className={!isEmbed ? "modeSelect-text active" : "modeSelect-text"}
            onClick={() => setIsEmbed(false)}
          >
            기술 확인 모드
          </div>
        </div>

        <div className="main-header-buttons">
          {/* <div className="multiDownload" onClick={() => handleMultiDownload(checkImagetest)}>
            멀티다운로드
          </div> */}
          <div className="logOut" onClick={logOut}>
            로그아웃
          </div>
        </div>
      </div>
      <div className={isEmbed ? "main-content" : "main-content-detect"}>
        <>
          {modal && (
            <DownloadModal
              title={curTitle}
              downloadInfo={downloadInfo}
              modalHandler={modalHandler}
              setStopDownload={setStopDownload}
            />
          )}
          {isEmbed ? (
            <div className="main-wrapper">
              <ContainerMenuBar
                viewMode={viewMode}
                setViewMode={setViewMode}
                trueCount={trueCount}
                handleClickCreateFile={handleClickCreateFile}
                handleMultiDownload={handleMultiDownload}
                checkImage={checkImage}
                CreateNormal={CreateNormal}
                CreateFileRef={CreateFileRef}
                refreshData={refreshData}
                handlePrintOut={handlePrintOut}
              />
              {modeSelection(viewMode)}
            </div>
          ) : (
            <DetectMode
              detectItem={detectItem}
              setDetectItem={setDetectItem}
              isDetected={isDetected}
              setIsDetected={setIsDetected}
              log={log}
              setLog={setLog}
              setErrorModal={setErrorModal}
            />
          )}
        </>
        {toastState && <ToastMessage message="링크가 복사되었습니다" />}
      </div>
      {isEmbed && (
        <BottomBar
          trueCount={trueCount}
          checkImageCount={checkImage.length}
          dataCount={data?.total}
        />
      )}
      <DeleteModal
        title="문서 삭제"
        content="관련 데이터가 모두 삭제 됩니다."
        isOpen={DeleteModalOpen}
        handleDeleteModal={handleDeleteModal}
        deleteFunction={normalDelete}
      />
      <ErrorModal
        title={errorModal.title}
        content={errorModal.content}
        errorModalOpen={errorModal.open}
        handleErrorModal={handleErrorModal}
      />
    </div>
  );
};

export default Normal;
