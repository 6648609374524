import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ToastMessage from "../../components/ToastMessage";
import ColorModePopup from "./ColorModePopup";
import Container from "./Container";

interface Props {
  CreateNormal: (e: { [key: string]: any }) => void;
  data: any;
  checkImage: boolean[];
  setCheckImage: any;
  handleClickUpdateFile: (e: { [key: string]: any }, id: string) => void;
  handleCheckImage: (i: number) => void;
  normalDeleteModal: (e: { [key: string]: any }, id: string) => void;
  handleClickNormal: (
    id: number,
    imgURL: string,
    isAdd: boolean,
    width: number,
    height: number,
    title: string,
    channel: string,
    colorSpace: string,
    link: string,
    alpha: number,
    mode: string
  ) => void;
  UpdateNormalImage: (e: { [key: string]: any }) => void;
  setToastState: any;
  setStopDownload: any;
  dataCount: number;
  setErrorModal: any;
  setEmbeddingMode: Dispatch<SetStateAction<string>>;
}

const GalleryMode = (props: Props) => {
  const UpdataFileRef = useRef<HTMLInputElement | null>(null);
  const CreateFileRef = useRef<HTMLInputElement | null>(null);
  const [limitCount, setLimitCount] = useState<number>(0);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASIC_URI}/program/users/product/creation/limit/count`
  //     )
  //     .then((data) => {
  //       setLimitCount(data.data.creationLimitCount);
  //     });
  // }, []);

  const resetCheckImage = (event: any) => {
    event.stopPropagation();
    let arr = Array.from({ length: props.checkImage.length }, () => false);
    props.setCheckImage(arr);
  };

  const handleClickCreateFile = () => {
    if (!CreateFileRef.current) return;
    // if (props.dataCount >= 8) {
    //   props.setErrorModal({
    //     open: true,
    //     title: "갯수 제한",
    //     content:
    //       "일시적인 현상이거나 네트워크 문제일 수 있으니\r\n 잠시 후 다시 시도해주세요.",
    //   });
    // }
    CreateFileRef.current.click();
    // else setColorModePopup(true);
  };

  return (
    <div className="container" id="list-scroller" onClick={resetCheckImage}>
      <div className="container-wrapper">
        <div
          className="container-card createFile"
          onClick={handleClickCreateFile}
        >
          <div className="plus"></div>
          <input
            type="file"
            name="CreateFile"
            ref={CreateFileRef}
            accept="image/* .pdf"
            onChange={props.CreateNormal}
            // disabled={props.dataCount >= 8 ? true : false}
          />
        </div>
        {props.data?.map((item: any, index: any) => {
          return (
            <Container
              item={item}
              index={index}
              handleClickNormal={(
                id: any,
                imgURL: string,
                isAdd: boolean,
                width: any,
                height: any,
                title: any,
                channel: string,
                colorSpace: string,
                link: string,
                alpha: number,
                mode: string
              ) =>
                props.handleClickNormal(
                  id,
                  imgURL,
                  isAdd,
                  width,
                  height,
                  title,
                  channel,
                  colorSpace,
                  link,
                  alpha,
                  mode
                )
              }
              handleClickUpdataFile={props.handleClickUpdateFile}
              handleCheckImage={props.handleCheckImage}
              UpdataFileRef={UpdataFileRef}
              key={index}
              title={item.title}
              isChecked={props.checkImage[index]}
              setToastState={props.setToastState}
              setStopDownload={props.setStopDownload}
            />
          );
        })}
        {/* <input
        type="file"
        name="UpdateFile"
        ref={UpdataFileRef}
        accept=".jpg, .jpeg, .png"
        onChange={props.UpdateNormalImage}
      /> */}
      </div>
    </div>
  );
};

export default GalleryMode;
