export const PRODUCT_PAGE_INIT = 'PRODUCT_PAGE_INIT' as const;
export const PRODUCT_ERROR = 'PRODUCT_ERROR' as const;
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS' as const;
export const PRODUCT_ID_INIT = 'PRODUCT_ID_INIT' as const;
export const PRODUCT_ID_SUCCESS = 'PRODUCT_ID_SUCCESS' as const;
export const PRODUCT_ID_ERROR = 'PRODUCT_ID_ERROR' as const;
export const PRODUCT_CREATE_INIT = 'PRODUCT_CREATE_INIT' as const;
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS' as const;
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR' as const;
export const PRODUCT_UPDATE_INIT = 'PRODUCT_UPDATE_INIT' as const;
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR' as const;
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS' as const;
export const PRODUCT_DELETE_INIT = 'PRODUCT_DELETE_INIT' as const;
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR' as const;
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS' as const;
export const DISPLAY_MORE_BEGIN = 'PRODUCT_DELETE_SUCCESS' as const;
export const DISPLAY_MORE_END = 'PRODUCT_DELETE_SUCCESS' as const;

export function productPageInit() {
  return {
      type: PRODUCT_PAGE_INIT,
  };
}

export function productError(error: any) {
  return {
      type: PRODUCT_ERROR,
      error,
  };
}

export function productSuccess(payload: any) {
  return {
      type: PRODUCT_SUCCESS,
      payload
  };
}
export function productIdInit(payload: any) {
  return {
      type: PRODUCT_ID_INIT,
      payload
  };
}

export function productIdError(error: any) {
  return {
      type: PRODUCT_ID_ERROR,
      error,
  };
}

export function productIdSuccess(payload: any) {
  return {
      type: PRODUCT_ID_SUCCESS,
      payload
  };
}
export function productCreateInit(payload: any) {
  return {
      type: PRODUCT_CREATE_INIT,
      payload
  };
}
export function productCreateError(error: any) {
  return {
      type: PRODUCT_CREATE_ERROR,
      error,
  };
}
export function productCreateSuccess(payload: any) {
  return {
      type: PRODUCT_CREATE_SUCCESS,
      payload
  };
}
export function productUpdateInit(payload: any) {
  return {
      type: PRODUCT_UPDATE_INIT,
      payload
  };
}
export function productUpdateError(error: any) {
  return {
      type: PRODUCT_UPDATE_ERROR,
      error,
  };
}
export function productUpdateSuccess(payload: any) {
  return {
      type: PRODUCT_UPDATE_SUCCESS,
      payload
  };
}
export function productDeleteInit(payload: any) {
  return {
      type: PRODUCT_DELETE_INIT,
      payload
  };
}
export function productDeleteError(error: any) {
  return {
      type: PRODUCT_DELETE_ERROR,
      error,
  };
}
export function productDeleteSuccess(payload: any) {
  return {
      type: PRODUCT_DELETE_SUCCESS,
      payload
  };
}
export type GetProductsActionsType =
  | ReturnType<typeof productPageInit>
  | ReturnType<typeof productError>
  | ReturnType<typeof productSuccess>
  | ReturnType<typeof productIdInit>
  | ReturnType<typeof productIdError>
  | ReturnType<typeof productIdSuccess>
  | ReturnType<typeof productCreateInit>
  | ReturnType<typeof productCreateSuccess>
  | ReturnType<typeof productCreateError>
  | ReturnType<typeof productUpdateInit>
  | ReturnType<typeof productUpdateError>
  | ReturnType<typeof productUpdateSuccess>
  | ReturnType<typeof productDeleteInit>
  | ReturnType<typeof productDeleteError>
  | ReturnType<typeof productDeleteSuccess>