import minus from "../../assets/icons/embed/minus.svg";
import plus from "../../assets/icons/embed/plus.svg";
import reset from "../../assets/icons/embed/reset.svg";
import { ReactComponent as Grab } from "../../assets/icons/embed/grab.svg";
import rotateImg from "../../assets/icons/embed/rotate.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerType } from "../../modules";
import { embedScale } from "../../modules/Embed";
import { useEffect, useState } from "react";

interface Props {
  panning: boolean;
  setPanning: any;
  setRotate: any;
  rotate: number;
  isAdd: boolean;
}

const BottomBar = (props: Props) => {
  const dispatch = useDispatch();
  const EmbedState = useSelector((state: RootReducerType) => state.Embed);
  const [initialScale, setInitialScale] = useState<number>(1);

  useEffect(() => {
    setInitialScale(Number(EmbedState.scale));
  }, []);

  const handleScaleUp = () => {
    if (EmbedState.scale < 3.0)
      dispatch(embedScale(Number(EmbedState.scale) + 0.1));
  };

  const handleScaleDown = () => {
    if (EmbedState.scale >= 0.2)
      dispatch(embedScale(Number(EmbedState.scale) - 0.1));
  };

  const handleScaleReset = () => {
    dispatch(embedScale(initialScale));
  };

  const handleRotate = () => {
    if (props.rotate === 270) {
      props.setRotate(0);
    } else {
      props.setRotate(props.rotate + 90);
    }
  };

  return (
    <div className="bottombar">
      {/* <div className="bottombar-container minus">
        <button onClick={handleScaleDown}>
          <img src={minus} />
        </button>
      </div>
      <div className="bottombar-container scale">{`${Math.round(
        EmbedState.scale * 100
      )}%`}</div>
      <div className="bottombar-container plus">
        <button>
          <img src={plus} onClick={handleScaleUp} />
        </button>
      </div>
      <div className="bottombar-container reset">
        <button>
          <img src={reset} onClick={handleScaleReset} />
        </button>
      </div>
      <div
        className={
          props.panning
            ? "bottombar-container grab active"
            : "bottombar-container grab"
        }
      >
        <button onClick={() => props.setPanning(!props.panning)}>
          <Grab />
        </button>
      </div> */}
      {/* <div className="bottombar-container rotate">
        <button onClick={handleRotate}>
          <img src={rotateImg} />
        </button>
      </div> */}
      <div>
        {props.isAdd
          ? "랩코드 적용 버튼을 누르면 기술이 적용됩니다."
          : "랩코드 기술이 적용된 이미지입니다. 기술세기를 수정할 수 있습니다."}
      </div>
    </div>
  );
};

export default BottomBar;
