import React, { useState, useRef, useEffect } from "react";
import KonvaCanvasdraw from "./KonvaCanvasdraw.js";

interface Props {
  imgURL: string;
  scale: number;
  setScale: any;
  panning: boolean;
  setPanning: any;
  rotate: number;
}

const ScreenContainer: React.FC<Props> = (props: Props) => {
  const screenContainer = useRef<HTMLDivElement | null>(null);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenWidth(Number(screenContainer.current?.offsetWidth));
    setScreenHeight(Number(screenContainer.current?.offsetHeight));
  }, []);

  return (
    <div className="screen-center-container-preview" ref={screenContainer}>
      <KonvaCanvasdraw
        scale={props.scale}
        setScale={props.setScale}
        KonvaWidth={screenWidth}
        KonvaHeight={screenHeight}
        imgURL={props.imgURL}
        panning={props.panning}
        setPanning={props.setPanning}
        rotate={props.rotate}
      />
    </div>
  );
};
export default ScreenContainer;
